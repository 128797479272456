.datnek-ds-content {
  min-height: 100vh;
}

.suggestion-header {
  background-color: $white;
  border-radius: $round-card $round-card 0 0;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid $gray-slate-300;
  .network-user-infos-small {
    network-avatar {
      width: 58px;

      @media (max-with: 400px) {
        width: 43px;
        img {
          width: 43px;
          height: 43px;
        }
      }
    }
    .infos {
      width: 100%;
    }
  }
  img {
    width: 40px;
  }
  @media (max-width: 575.98px) {
    padding: 1rem;
  }
}

.category-body {
  height: 226px;
  overflow-y: scroll;
}

.suggestion-footer {
  background-color: $white;
}

.groups-list {
  height: 160px;
  //overflow-y: scroll;
}

// ACCEPTED CHALLEGE NAME =============================================
.accepted-callenges-name {
  .suggestion-header {
    padding: 1rem;
    border-bottom: 1px solid $gray-slate-300;
    img {
      width: 40px;
    }
    @media (max-width: 575.98px) {
      padding: 1rem;
    }
  }

  network-challenge-sidebar {
    position: sticky;
    top: 75px;
  }
}

// CHALLENGE DESCRIPTION ======================================
.challenge-description {
  &.content {
    padding-top: 5rem;
    min-height: 100vh;

    .header {
      height: 70px;
      border-bottom: 1px solid $gray-slate-300;
    }
    .footer {
      height: 70px;
      border-top: 1px solid $gray-slate-300;
    }
  }

  .img {
    width: 100%;
    overflow: hidden;
    height: 165px;
    img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }
}

// CHALLENGE LIST ==============================================
.challenge-list {
  .sticky-sidebar {
    @media (min-width: 768px) {
      position: sticky;
      top: 70px;
    }
  }
  .suggestion-list-card {
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    .float-time {
      position: absolute;
      right: 16px;
      top: 20px;
      z-index: 0;
      width: 105px;
      opacity: 1;
      text-align: right;
      &.your-challenge {
        top: 55px;
      }
    }
    &.one-card {
      grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
      @media (max-width: 991.98px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  network-challenge-sidebar {
    position: sticky;
    top: 75px;
  }

  .content-filter {
    .side {
      width: 50px;
    }
    .custom-slider-main {
      display: flex;
      overflow: hidden;
      scroll-behavior: smooth;
      width: 300px;
    }
  }

  .suggestion-header {
    background-color: $white;
    border-radius: $round-card $round-card 0 0;
    .network-user-infos-small {
      network-avatar {
        width: 58px;

        @media (max-with: 400px) {
          width: 43px;
          img {
            width: 43px;
            height: 43px;
          }
        }
      }
      .infos {
        width: 100%;
      }
    }
  }

  .category-body {
    height: calc(100vh - 273px);
    overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .suggestion-footer {
    padding: 0.7rem;
    border-top: 1px solid #e9e7e7;
    border-radius: 0 0 $round-card $round-card;
  }

  .suggestion-header {
    padding: 1rem;
    border-bottom: 1px solid $gray-slate-300;
    img {
      width: 40px;
    }
    @media (max-width: 575.98px) {
      padding: 1rem;
    }
  }

  .category {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 1rem;
    border-bottom: 1px solid $gray-slate-300;
    transition: all ease-in 0.15s;
    network-svg-icon {
      display: none;
    }
    &:hover {
      background-color: $home-gray;
      network-svg-icon {
        display: block;
      }
    }
  }

  .filter {
    display: flex;
    gap: 16px;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    @media (max-width: 991.98px) {
      -ms-overflow-style: none !important;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .chip {
      min-width: -moz-fit-content;
      min-width: fit-content;
      background-color: $home-gray;
      padding: 0rem 1.4rem;
      border-radius: 26px;
      display: flex;
      align-items: center;
      height: 30px;
      cursor: pointer;
      &.active {
        background-color: $primary;
        color: $white;
      }
    }
  }

  network-no-content {
    margin-top: -16px;
    margin-bottom: -16px;
    margin-left: -16px;
    margin-right: -16px;
  }

  .menu-tri {
    position: relative;
    .submenu-tri {
      display: none;
      position: absolute;
      right: 0;
      top: 31px;
      border-radius: $round-card;
      background-color: $white;
      width: 200px;
      border: 1px solid $gray-slate-300;
      z-index: 2;
      hr {
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
      }
      button {
        width: 100%;
        text-align: left;
        padding: 0.5rem 1rem;
      }
    }
  }

  .dropdown-menu.show {
    transform: translate(-128px, 35px) !important;
  }
}

// CHALLENGE ACCEPTED ==============================================
.challenge-accepted {
  .sticky-sidebar {
    @media (min-width: 768px) {
      position: sticky;
      top: 70px;
    }
  }
  .suggestion-list-card {
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    .float-time {
      position: absolute;
      right: 16px;
      top: 20px;
      z-index: 0;
      width: 105px;
      opacity: 1;
      text-align: right;
      &.your-challenge {
        top: 55px;
      }
    }
  }

  network-challenge-sidebar {
    position: sticky;
    top: 75px;
  }

  .content-filter {
    .side {
      width: 50px;
    }
    .custom-slider-main {
      display: flex;
      overflow: hidden;
      scroll-behavior: smooth;
      width: 300px;
    }
  }

  .suggestion-header {
    background-color: $white;
    border-radius: $round-card $round-card 0 0;
    .network-user-infos-small {
      network-avatar {
        width: 58px;

        @media (max-with: 400px) {
          width: 43px;
          img {
            width: 43px;
            height: 43px;
          }
        }
      }
      .infos {
        width: 100%;
      }
    }
  }

  .category-body {
    height: calc(100vh - 273px);
    overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .suggestion-footer {
    padding: 0.7rem;
    border-top: 1px solid #e9e7e7;
    border-radius: 0 0 $round-card $round-card;
  }

  .suggestion-header {
    padding: 1rem;
    border-bottom: 1px solid $gray-slate-300;
    img {
      width: 40px;
    }
    @media (max-width: 575.98px) {
      padding: 1rem;
    }
  }

  .category {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 1rem;
    border-bottom: 1px solid $gray-slate-300;
    transition: all ease-in 0.15s;
    network-svg-icon {
      display: none;
    }
    &:hover {
      background-color: $home-gray;
      network-svg-icon {
        display: block;
      }
    }
  }

  .filter {
    display: flex;
    gap: 16px;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    @media (max-width: 991.98px) {
      -ms-overflow-style: none !important;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .chip {
      min-width: -moz-fit-content;
      min-width: fit-content;
      background-color: $home-gray;
      padding: 0rem 1.4rem;
      border-radius: 26px;
      display: flex;
      align-items: center;
      height: 30px;
      cursor: pointer;
      &.active {
        background-color: $primary;
        color: $white;
      }
    }
  }

  network-no-content {
    margin-top: -16px;
    margin-bottom: -16px;
    margin-left: -16px;
    margin-right: -16px;
  }

  .menu-tri {
    position: relative;
    .submenu-tri {
      display: none;
      position: absolute;
      right: 0;
      top: 31px;
      border-radius: $round-card;
      background-color: $white;
      width: 200px;
      border: 1px solid $gray-slate-300;
      z-index: 2;
      hr {
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
      }
      button {
        width: 100%;
        text-align: left;
        padding: 0.5rem 1rem;
      }
    }
  }

  // .dropdown-formly-menu.show {
  //   display: block;
  //   position: fixed !important;
  //   bottom: 89px !important;
  //   top: unset !important;
  //   width: 100%;
  //   z-index: 12000000;
  //   padding: 16px;
  // }
}

// YOUR CHALLENGES ==============================================
.your-challenges {
  .sticky-sidebar {
    @media (min-width: 768px) {
      position: sticky;
      top: 70px;
    }
  }
  .suggestion-list-card {
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    .float-time {
      position: absolute;
      right: 16px;
      top: 20px;
      z-index: 0;
      width: 105px;
      opacity: 1;
      text-align: right;
      &.your-challenge {
        top: 55px;
      }
    }
  }

  network-challenge-sidebar {
    position: sticky;
    top: 75px;
  }

  .content-filter {
    .side {
      width: 50px;
    }
    .custom-slider-main {
      display: flex;
      overflow: hidden;
      scroll-behavior: smooth;
      width: 300px;
    }
  }

  .suggestion-header {
    background-color: $white;
    border-radius: $round-card $round-card 0 0;
    .network-user-infos-small {
      network-avatar {
        width: 58px;

        @media (max-width: 400px) {
          width: 43px;
          img {
            width: 43px;
            height: 43px;
          }
        }
      }
      .infos {
        width: 100%;
      }
    }
  }

  .category-body {
    height: calc(100vh - 273px);
    overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .suggestion-footer {
    padding: 0.7rem;
    border-top: 1px solid #e9e7e7;
    border-radius: 0 0 $round-card $round-card;
  }

  .suggestion-header {
    padding: 1rem;
    border-bottom: 1px solid $gray-slate-300;
    img {
      width: 40px;
    }
    @media (max-width: 575.98px) {
      padding: 1rem;
    }
  }

  .category {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 1rem;
    border-bottom: 1px solid $gray-slate-300;
    transition: all ease-in 0.15s;
    network-svg-icon {
      display: none;
    }
    &:hover {
      background-color: $home-gray;
      network-svg-icon {
        display: block;
      }
    }
  }

  .filter {
    display: flex;
    gap: 16px;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    @media (max-width: 991.98px) {
      -ms-overflow-style: none !important;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .chip {
      min-width: -moz-fit-content;
      min-width: fit-content;
      background-color: $home-gray;
      padding: 0rem 1.4rem;
      border-radius: 26px;
      display: flex;
      align-items: center;
      height: 30px;
      cursor: pointer;
      &.active {
        background-color: $primary;
        color: $white;
      }
    }
  }

  network-no-content {
    margin-top: -16px;
    margin-bottom: -16px;
    margin-left: -16px;
    margin-right: -16px;
  }

  .menu-tri {
    position: relative;
    .submenu-tri {
      display: none;
      position: absolute;
      right: 0;
      top: 31px;
      border-radius: $round-card;
      background-color: $white;
      width: 200px;
      border: 1px solid $gray-slate-300;
      z-index: 2;
      hr {
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
      }
      button {
        width: 100%;
        text-align: left;
        padding: 0.5rem 1rem;
      }
    }
  }

  // .dropdown-formly-menu.show {
  //   display: block;
  //   position: fixed !important;
  //   bottom: 89px !important;
  //   top: unset !important;
  //   width: 100%;
  //   z-index: 12000000;
  //   padding: 16px;
  // }
}

// CHALLENGE NAME FINISHED ==================================
.challenge-name-finished {
  network-challenge-sidebar {
    position: sticky;
    top: 75px;
  }
}

// CHALLENGE STATS ==========================================
.challenge-stats {
  .bg-container {
    background-color: #f0f2f5;
    min-height: 100vh;
  }

  .scores {
    .title-score {
      border-bottom: 1px solid $gray-slate-300;
    }
    .challenge-name {
      color: $primary;
    }
    .score-items {
      padding: 1rem;
      border-bottom: 1px solid $gray-slate-300;
      .title {
        width: 50%;
      }
      .jauge {
        width: 30%;
        height: $h-progressbar;
        background-color: $gray-300;
        overflow: hidden;
        position: relative;
        border-radius: 2px;
        .bar {
          position: absolute;
          height: inherit;
          background-color: $primary;
        }
      }
      .note {
        width: 20%;
      }
    }
    .message-jury {
      //background-color: $home-gray;
      border-top: 1px solid $gray-slate-300;
      img {
        width: 30px;
      }
    }
  }

  .title-score {
    border-bottom: 1px solid $gray-slate-300;
  }

  network-card-user-box {
    position: sticky;
    top: 70px;
  }
  .h-score {
    //height: 300px;
    //overflow-y: scroll;
  }

  .other-scores {
    //overflow-x: scroll;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(292px, 1fr));
    height: auto;
    //width: calc(100vw - 620px);
    //overflow-y: scroll;
    gap: 1rem;
    //grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    margin: auto;
    @media (max-width: 767.99px) {
      width: 100%;
    }
    @media (min-width: 768px) {
      width: 600px;
    }
    .score-item {
      width: auto;
      //padding: 1rem;
      /* border-left: 1px solid lightgray; */
      border-right: 1px solid $gray-slate-300;
      .score-head {
        border-bottom: 1px solid $gray-slate-300;
        .display-5 {
          color: $gray-300;
        }
      }
    }
    .chart {
      position: relative;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      transform: scale(0.7);
      svg {
        width: 70px;
        height: 70px;

        circle {
          fill: none;
          stroke: $primary;
          stroke-width: 3px;
          transform: translate(5px, 5px);
          stroke-dasharray: 190;
        }
      }
      span {
        position: absolute;
        font-size: 1.5rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .medal {
      width: 50px;
      height: 50px;
      border-radius: $round-full;
      overflow: hidden;
      &.no-medal {
        background-color: $home-gray;
        img {
          opacity: 0.5;
        }
      }
      img {
        object-fit: cover;
        border: 1px solid $gray-slate-300;
        width: 100%;
      }
    }
  }
}

// MY CHALLENGE NAME =========================================
.my-challenges-name {
}

// PUBLISH CHALLENGE =======================================
.publish-challenge-content {
  .result-criteria-form,
  .results {
    position: relative;
    width: 93%;
    .inputs {
      width: 100%;
      .criteria {
        width: 85%;
        position: relative;
        network-label {
          position: absolute;
          top: -23px;
          left: 0;
        }
        span {
          height: $h-input;
          border-radius: $round;
          border: 1px solid $gray-300;
          padding-left: 12px;
          padding-right: 12px;
          width: 100%;
          display: flex;
          align-items: center;
          &.id {
            width: 10%;
          }
          &.title {
            width: 90%;
          }
        }
      }
      .note {
        width: 15%;
        position: relative;
        network-label {
          position: absolute;
          top: -23px;
          left: 0;
        }
        span {
          height: $h-input;
          border-radius: $round;
          border: 1px solid $gray-300;
          padding-left: 12px;
          padding-right: 12px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    network-button-icon {
      position: absolute;
      right: -45px;
      top: 3px;
    }
  }

  form.ng-invalid {
    border: 0px dashed #e33232 !important;
  }

  input.ng-invalid {
    border: 1px solid rgba($gray-300, 0.65) !important;
  }

  .footer-modal {
    height: 58px;
    padding: $card-padding;
    padding-bottom: 0;
    border-top: 1px solid $gray-slate-300;
    margin-left: -16px !important;
    margin-right: -16px;
  }
}

@import 'src/lib/ui/menus/menu';
@import 'src/lib/ui/components/security/security';

.modal-body {
    .header-body {
        .langues {
            .active {
                font-weight: 600;
                color: #000;
              }
        }
    }
    .content-body {
        .nav-pills {
            width: 35%;
            gap: .5rem;
            li {
                a {
                    &.nav-link{
                        background-color: #e8e8e8;
                        color: $dark !important;
                        &.active {
                            color: $white !important;
                            font-weight: 500;
                            background: $primary !important;
                              background-color: transparent;
                          }
                    }
                    }
                }
        }
        .tab-content {
            flex: 1 1 auto !important;
            width: 100%;
            .columns {
                width: 100%;
                column-count: 4;
                a {
                    display: block;
                  }
            }
        }
    }
}

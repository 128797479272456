@import "shared/shared";
@import "network/network";

.add-member {
    display: flex;
    gap: 16px;
    .member-item {
        position: relative;
        .close{
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
            height: 20px;
            background-color: $primary;
            color: $white;
            display: flex;
            border-radius: 20px;
            font-size: 0.8rem;
            align-items: center;
            justify-content: center;
        }
    }
}

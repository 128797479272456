/**************  ORGANISMS STYLES ****************/

// CARD NEW PUBLICATION BOX --------------------------------------
.new-publication {
  padding: $card-padding;
  .left {
    width: 75px;
  }
  .right {
    width: calc(100% - -58px);
    .message {
      .text-box {
        border-radius: $round-card;
        border: 2px solid $gray-slate-300;
        height: $h-avatar-medium;
        padding: 0 20px;
        cursor: pointer;
      }
    }
  }
  .icons {
    display: flex;
    align-items: center;
    position: unset;
    width: 100%;
    gap: 14px;
    datnek-ds-button {
      flex: 1;
      width: 100px;
    }
  }
  .datnek-ds-button--large {
    padding: 0px 12px !important;
  }
}

//  CARD PUBLICATION BOX ----------------------------------------
// Card publication box
.publication-box {
  padding: $card-padding;
  background-color: $white;
  z-index: 0;
  .text {
    margin-left: 45px;
    @media (max-width: 575.98px) {
      margin-left: 0px;
    }
  }
  .buttons-icons {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 14px;
    datnek-ds-button,
    button {
      flex: 1;
    }
  }
  .display-6 {
    color: $gray-300;
  }
  .share-post {
    flex: 1;
  }
  .actions {
    margin-top: 15px;
  }

  .video-publication,
  .gallery-publication,
  .document-publication {
    margin-left: -$card-padding !important;
    margin-right: -$card-padding !important;
  }

  .document-publication {
    .document-box {
      cursor: pointer;
      .viewbox {
        width: 100%;
        height: 450px;
        background: url('/assets/Img/bg/bg-placeholder.svg') no-repeat center
          center;
        background-size: cover;
        overflow: hidden;
        img {
          width: 80%;
          height: max-content;
          margin-top: 50px;
        }
      }
      .infosbox {
        padding: 2rem;
        background-color: #d9d9d9;
      }
    }
  }

  .article-box,
  .group-box {
    border-radius: $round-card;
    overflow: hidden;
    background-color: $secondary;
    @media (max-width: 767.98px) {
      border-radius: 0;
      margin-left: -16px;
      margin-right: -16px;
    }
    .img {
      width: 100%;
      height: 350px;
      img {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }
  }

  .datnek-ds-card-likes-buttons {
    .like-btn {
      position: relative;
      opacity: 1;
      flex: 1;
      .likes-icons {
        position: absolute;
        gap: 10px;
        top: -70px;
        left: -20px;
        padding: 16px;
        cursor: pointer;
        transition: all ease-in 0.18s;
        z-index: 1;
        border-radius: 13px;
        &.grid-icons {
          display: flex !important;
          width: 212px;
          overflow: hidden;
          datnek-ds-button-icon {
            position: relative !important;
            right: 0 !important;
            top: 0 !important;
          }
          &.full-grids {
            display: grid !important;
            width: auto !important;
            grid-template-columns: repeat(4, 1fr);
            //transform: translateY(90px);
          }
        }
        img {
          &:hover {
            transform: scale(1.5);
            transform-origin: center;
          }
        }
      }
    }
  }

  .datnek-ds-card-likes {
    img {
      width: 19px;
      height: 19px;
    }
  }

  .datnek-ds-card-header {
    .dropdown-menu.show {
      transform: translate(-243px, 35px) !important;
    }
    nk-shared-user-infos {
      .dropdown-menu.show {
        transform: translate(0, 35px) !important;
      }
    }
  }

  .network-icon-options-dropdown {
    /* width: 100%; */
    position: absolute !important;
    right: 16px;
    top: 12px;
  }

  .follow {
    position: absolute;
    right: 16px;
    top: 12px;
  }

  .likes-icons {
    display: none;
  }

  &.border {
    border: 1px solid #c0b9b9 !important;
  }

  .dropdown-card {
    &.card-publication-actions {
      width: 300px;
      transform: translateX(-250px);
      border-radius: $round-card;
      border: 1px solid $gray-slate-300;
      padding: 0.5rem;
    }
  }
  .network-icon-options-dropdown {
    // .dropdown-formly-menu.show {
    //   transform: translate(-283px, 32px) !important;
    //   width: 320px !important;
    // }
  }

  .pertinent-dropdown {
    // .dropdown-formly-menu.show {
    //   transform: translate(-14px, 32px) !important;
    //   width: 165px !important;
    // }
  }

  .share-post {
    // .dropdown-formly-menu.show {
    //   display: block;
    //   transform: translate(-80px, 42px) !important;
    // }
  }

  .user-infos {
    .dropdown-menu.show {
      transform: translate(-243px, 35px);
    }
  }

  .list-comments {
    border-top: 1px solid #d6d6d6;
    margin-left: -16px;
    margin-right: -16px;
    padding: 16px;
  }
}

// Comments lightbox
.comment-lightbox {
  .comments-publications {
    height: 100vh;
    position: relative;
    header {
      .img-profile {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 1rem;
        img {
          width: inherit;
          height: inherit;
          object-fit: cover;
        }
      }
      .post-card-titles {
        h5 {
          font-size: 0.88rem;
          margin-bottom: 0.2rem;
        }
        span {
          font-size: 0.7rem;
        }
      }
    }
    .list-comments {
      height: auto;
      overflow: hidden;
      padding: 0 16px;
      padding-bottom: 4rem;
      .container {
        width: auto;
        .card {
          img {
            border-radius: 10px;
            padding-right: 5px;
            width: 60px;
            height: 55px;
            :hover {
              cursor: pointer;
            }
          }
          padding: 0;
          .media {
            .comment {
              background: #f4f4f4;
              border: none;
              border-radius: 10px;
            }
          }
          .round .align-self-start {
            border-radius: 100%;
            width: $img-tiny;
            height: 40px;
          }
        }
        p.text {
          margin-bottom: 0;
          color: #8a8a8a !important;
          font-size: 14px;
        }

        .ml-auto {
          margin-right: 10px;
        }

        p .reply {
          color: #5c5c5c;
          font-size: 15px;
        }
      }
    }
    .footer {
      position: absolute;
      bottom: 5px;
      width: 100%;
      margin-left: -16px;
      padding: 0px 16px;
      @media (max-width: 767.98px) {
        position: fixed;
        bottom: 0;
        z-index: 2;
        background: white;
        height: 62px;
        padding-top: 0.5rem;
      }
    }
  }

  .datnek-ds-card-likes-buttons {
    .buttons-icons {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 14px;
      datnek-ds-button {
        flex: 1;
      }
      .share-post {
        flex: 1;
      }
    }
    .like-btn {
      position: relative;
      opacity: 1;
      flex: 1;
      .likes-icons {
        position: absolute;
        gap: 10px;
        top: -70px;
        left: -20px;
        padding: 16px;
        cursor: pointer;
        transition: all ease-in 0.18s;
        z-index: 1;
        border-radius: 13px;
        width: auto;
        gap: 10px;
        display: none;
        &.grid-icons {
          display: flex !important;
          width: 212px;
          overflow: hidden;
          datnek-ds-button-icon {
            position: relative !important;
            right: 0 !important;
            top: 0 !important;
          }
          &.full-grids {
            display: grid !important;
            width: auto !important;
            grid-template-columns: repeat(4, 1fr);
            //transform: translateY(90px);
          }
        }
        img {
          &:hover {
            transform: scale(1.5);
            transform-origin: center;
          }
        }
      }
    }
  }

  .datnek-ds-card-likes {
    .left {
      gap: 13px;
    }
  }

  nk-shared-comment-add {
    position: fixed;
    bottom: 0;
    z-index: 1;
    right: 0;
    width: 30%;
    padding: 6px 10px;
    background: white;
    /* margin-top: 0 !important; */
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .p-scrollpanel-content {
    padding: 0 10px 18px 0 !important;
    position: relative;
    width: calc(100% + 18px);
  }

  .list-comments {
    margin-top: 1rem;
    ul {
      list-style: none;
      padding: 0;
      .comment {
        display: block;
        gap: 8px;
        margin-bottom: 0.8rem;
        .left {
          width: 15%;
          height: auto;
          .avatar {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            overflow: hidden;
          }
          img {
            width: inherit;
            height: inherit;

            object-fit: cover;
          }
        }
        .comment-item {
          padding: 0.5rem;
          background: #e9ecef;
          border-radius: 8px;
          width: 85%;
          h6 {
            font-size: 0.8rem !important;
          }
          .timer {
            /* margin-left: -10px; */
            position: relative;
            left: -10px;
          }
          .actions {
            display: flex;
            justify-content: flex-end;
            font-weight: bold;
            font-size: 0.8rem;
          }
        }
        ul {
          margin-left: 40px;
          margin-top: 16px;
          width: calc(100% - 40px);
        }
      }
    }
  }
  .desc-txt {
    margin-bottom: 0;
  }

  .more {
    font-size: 0.9rem;
  }
}

// Comment pushed
.comment-pushed {
  .thumbnail-view {
    width: 100%;
    .items {
      width: 100%;
      .item-list {
        display: grid;
        gap: 1rem;
        //grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        grid-template-columns: repeat(5, 1fr);
        img {
          width: 100%;
          height: 120px;
          object-fit: cover;
          border-radius: 6px;
          overflow: hidden;
        }
        figure {
          width: 100%;
          height: 125px;
          margin-bottom: 0;
          position: relative;
          overflow: hidden;
          border-radius: 6px;
          .play {
            width: 40px;
            position: absolute;
            z-index: 2;
            img {
              height: auto;
            }
          }
          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            display: block;
            background-color: rgba($text, 0.5);
            z-index: 1;
            border-radius: 6px;
          }
          video {
            width: 100%;
            height: 100%;
            transform: scale(1.8);
          }
        }
        &.link-list {
          grid-template-columns: repeat(3, 1fr);
          @media (max-width: 575.98px) {
            grid-template-columns: repeat(1, 1fr);
          }
          @media (max-width: 767.98px) {
            grid-template-columns: repeat(2, 1fr);
          }
        }
        .link {
          border-radius: $round-card;
          overflow: hidden;
          .img {
            width: 100%;
            height: 100px;
            margin: 0;
            img {
              width: inherit;
              height: inherit;
              object-fit: cover;
              border-radius: 0;
            }
          }
        }
      }
    }
    .icon-close {
      position: relative;
      top: -10px;
    }
  }
  .dropdown-menu.show {
    display: block;
    border-radius: 16px;
    .follow-box {
      width: 350px;
      padding: $card-padding;
      .buttons {
        .datnek-ds-button {
          flex: 1;
        }
      }
    }
  }
}

// Comment User Infos
.follow-box {
  width: auto;
  padding: 8px;
  &.comment_user {
    width: 350px;
  }
  .buttons {
    .datnek-ds-button {
      flex: 1;
    }
  }
}

// datnek-ds-card-likes
.datnek-ds-card-likes {
  .stat-post {
    position: relative;
    transition: all ease-in 0.5s;
    &:hover {
      .drop-stat-post {
        display: block;
      }
    }
    .drop-stat-post {
      position: absolute;
      display: none;
      top: 25px;
      left: 0;
      padding: $card-padding;
      width: 150px;
      ul {
        padding: 0;
        list-style: none;
        margin-bottom: 0;
        li {
          margin-bottom: 2;
        }
      }
    }
  }
}

// CHALLENGE CARDS -------------------------------------------------
.suggestion-list-card {
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  .float-time {
    position: absolute;
    right: 16px;
    top: 20px;
    z-index: 0;
    width: 105px;
    opacity: 1;
    text-align: right;
    &.your-challenge {
      top: 55px;
    }
  }
}

// SUGGESTION CARD MOBILE ============================================
.suggestion-card-mobile {
  .video {
    height: auto; //150px
    background-color: #212121;
    position: relative;
    overflow: hidden;
    .play {
      position: absolute;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
      z-index: 1;
    }
    video {
      width: 100% !important;
      height: auto;
    }
  }
}

// Card challenge =====================================
.card-challenge {
  .challenge-box {
    .challenge-header {
      .title {
        width: calc(100% - 95px);
        @media (max-width: 575.98px) {
          width: calc(100% - 57px);
        }
      }
      .subtitle {
        @media (max-width: 575.98px) {
          width: calc(100% - 57px);
        }
      }
    }
  }
}

// Card challenge box
.card-challenge-box {
  &.challenge-box {
    border: 1px solid $gray-slate-300;
    position: relative;
    overflow: hidden;
    .float-img {
      position: absolute;
      right: -16px;
      top: -14px;
      z-index: 0;
      width: 73px;
      opacity: 1;
    }
    .float-time {
      position: absolute;
      right: 16px;
      top: 25px;
      z-index: 0;
      width: 105px;
      opacity: 1;
      text-align: right;
      &.your-challenge {
        top: 55px;
      }
    }
    .challenge-header {
      padding: $card-padding;
      position: relative;
      min-height: 58px;
      //border-bottom: 1px solid $gray-slate-300;
      img {
        width: $img-small-tiny;
      }
    }
    .challenge-content {
      img {
        // margin: auto;
        width: 100% !important;
        height: 85px;
        object-fit: cover;
      }
      .society {
        border: 1px solid $gray-slate-300;
      }
    }
    .icons {
      display: flex;
      align-items: center;
      position: unset;
      width: 100%;
      gap: 14px;
      datnek-ds-button {
        flex: 1;
      }
    }
    .challenge-footer {
      padding: 1rem;
      border-top: 1px solid $gray-slate-300;
      gap: 14px;
      datnek-ds-button {
        flex: 1;
      }
    }
  }
  .title {
    width: calc(100% - 100px);
    .subtitle {
      color: $gray-300;
      font-weight: 400 !important;
    }
    .display-1,
    .subtitle {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 23px;
    }
  }

  .timer {
    display: flex;
    width: auto !important;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-left: 1rem;
    text-align: center;
    border-radius: 13px;
    background: #f0f2f5;
  }

  .datnek-ds-card-footer {
    border-top: 1px solid $gray-slate-300;
  }

  .right {
    margin-top: -8px;
  }
}

// Card challenge description
.card-challenge-description {
  &.user-box {
    border-radius: $round-card;

    .header {
      height: 100px;
      width: 100%;
      overflow: hidden;
      border-radius: $round-card $round-card 0 0;
      position: relative;
      background-color: #e3eaef;
      img {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }
    .content {
      text-align: center;
      .avatar {
        display: inline-flex;
        justify-content: center;
        position: relative;
        margin-top: -30px;
        width: 58px;
        height: 58px;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 0 0px 4px white;
        img {
          width: inherit;
          height: inherit;
          object-fit: cover;
        }
      }
      .infos {
        padding: 1rem;
        .title {
          img {
            // width: 30px;
            // height: 30px;
            // margin-left: 12px;
            // margin-bottom: 0;
            // transform: scale(0.6);
            // position: absolute;
            // top: -4px;
          }
        }
      }
      .footer {
        padding: 1.5rem;
        border-top: 1px solid $gray-slate-300;
      }
      .challenge-footer {
        padding: 1rem;
        border-top: 1px solid $gray-slate-300;
        gap: 14px;
        datnek-ds-button,
        .share-post {
          flex: 1;
        }
      }
    }
  }

  .challenge-box {
    position: relative;
    overflow: hidden;
    .float-img {
      position: absolute;
      right: -16px;
      top: -14px;
      z-index: 0;
      width: 73px;
      opacity: 1;
    }
    .float-time {
      position: absolute;
      right: 16px;
      top: 20px;
      z-index: 0;
      width: 105px;
      opacity: 1;
      text-align: right;
      &.your-challenge {
        top: 55px;
      }
    }
    .challenge-header {
      padding: $card-padding;
      position: relative;
      min-height: 58px;
      //border-bottom: 1px solid $gray-slate-300;
      img {
        width: $img-small-tiny;
      }
    }
    .challenge-content {
      img {
        // margin: auto;
        width: 100%;
        height: 85px;
        object-fit: cover;
      }
      .society {
        border: 1px solid $gray-slate-300;
      }
    }
    .icons {
      display: flex;
      align-items: center;
      position: unset;
      width: 100%;
      gap: 14px;
      datnek-ds-button {
        flex: 1;
      }
    }
  }

  .description {
    height: 208px;
    max-height: 250px;
    display: flex;
    align-items: center;
  }
}

// Card challenger Box
.card-challenger-box {
  &.user-box {
    border-radius: $round-card;
    &.chat {
      .header {
        height: 70px;
      }
      .content {
        .avatar {
          margin-top: -30px;
        }
      }
    }
    .header {
      height: 100px;
      width: 100%;
      overflow: hidden;
      border-radius: $round-card $round-card 0 0;
      position: relative;
      datnek-ds-button-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
      }
      img {
        width: inherit !important;
        height: inherit;
        object-fit: cover;
      }
    }
    .content {
      text-align: center;
      .avatar {
        display: flex;
        justify-content: center;
        position: relative;
        margin-top: -30px;
        &.versus {
          margin-top: 30px;
        }
        datnek-ds-button-icon {
          position: absolute;
          top: 20px;
          left: 45%;
          z-index: 1;
        }
      }
      .awards {
        gap: 6px;
        img {
          height: 20px;
        }
      }
      .infos {
        padding: 1rem;
        //margin-top: 55px;
        .title {
          .username {
            display: block;
            max-width: 120px;
            color: $text;
            position: relative;
            span {
              color: $text;
            }
            img {
              width: 30px;
              height: 30px;
              margin-left: -10px;
              margin-bottom: 0;
              transform: scale(0.6);
              position: relative;
              right: -10px;
              //top: -4px;
            }
          }
        }
      }
      .competences {
        padding: 0 1.5rem 1.5rem;
        height: 45px;
        overflow: hidden;
        &.open {
          height: auto;
        }
        span {
          color: $gray-300;
          &:before {
            content: '';
            width: 5px;
            height: 5px;
            background-color: black;
            display: inline-block;
            border-radius: 50%;
            top: -3px;
            position: relative;
          }
        }
        .readmore {
        }
      }
      .stats {
        padding: 1.5rem 0rem;
        border-top: 1px solid $gray-slate-300;
        .datas {
          .left,
          .right,
          .center {
            width: 100%;
          }
          .left,
          .center {
            border-right: 1px solid $gray-slate-300;
          }
        }
      }
      .footer {
        padding: 1.5rem;
        border-top: 1px solid $gray-slate-300;
      }
      .challenge-footer {
        padding: 1rem;
        border-top: 1px solid $gray-slate-300;
        gap: 14px;
        datnek-ds-button,
        .share-post {
          flex: 1;
        }
      }
    }
  }
}

// Card ChallengeComp Battle Box
.challenge-battle-box {
  &.challenge-box {
    .challenge-header {
      padding: 1rem;
      //border-bottom: 1px solid $gray-slate-300;
      img {
        width: 30px;
      }
    }
    .challenge-content {
      img {
        width: $img-tiny;
        margin: auto;
        margin-bottom: 1rem;
      }
      .challenge-name {
        color: $primary;
      }
    }
    .challenge-footer {
      padding: 1rem;
      border-top: 1px solid $gray-slate-300;
      gap: 14px;
      datnek-ds-button {
        flex: 1;
      }
    }
  }

  .challenger {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .challengers {
    .item {
      position: relative;
      width: 100%;
      border-radius: $round-card;
      //padding: 0.5rem 1rem;
      margin-bottom: 1rem;
      @media (max-width: 575.98px) {
        padding: 0;
      }
      &.left {
        //transform: translateX(22px);
        .avatar {
          margin-left: -58px;
          transform: translateX(50%);
          @media (max-width: 575.98px) {
            //transform: translateX(-18px);
            transform: translateX(-50%);
            margin-left: 50%;
            left: 0;
          }
          img {
            left: 0;
            @media (max-width: 575.98px) {
              left: 12px;
            }
          }
        }
      }
      &.right {
        // transform: translateX(-22px);
        .avatar {
          margin-right: -58px;
          transform: translateX(-50%);
          @media (max-width: 575.98px) {
            //transform: translateX(18px);
            transform: translateX(50%);
            margin-right: 50%;
            left: 0;
          }
          img {
            right: 0;
            @media (max-width: 575.98px) {
              right: 12px;
            }
          }
        }
      }
      .m-right {
        //margin-right: -22px;
        @media (max-width: 575.98px) {
          margin-right: 0;
        }
      }
      .m-left {
        //margin-left: -22px;
        @media (max-width: 575.98px) {
          margin-right: 0;
        }
      }
      .avatar {
        position: relative;
        img {
          position: absolute;
          display: block;
          bottom: 0;
          width: 20px;
          height: 20px;
          margin-bottom: 0;
          z-index: 10;
        }
      }
      .infos {
        span {
          color: $gray-300;
        }
      }
    }
    .vs {
      margin-top: calc(100% - 75%);
      img {
        width: 50px;
        height: auto;
        transform: translateY(-50%);
        margin-bottom: 0;
        @media (max-width: 575.98px) {
          width: 30px;
        }
      }
    }
  }

  .organizer {
    border: 1px solid $gray-slate-300;
  }

  .infos {
    width: 100%;
    &:hover {
      text-decoration: none;
    }
    .name {
      color: $text;
      position: relative;
      img {
        position: absolute;
        right: -30px;
        width: 18px;
      }
    }
    span {
      color: $gray-300;
    }
  }

  nk-shared-card-user-box-simple {
    flex: 1;
  }

  .owl-theme .owl-nav [class*='owl-'] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 4px;
    background: transparent !important;
    //display: none;
    cursor: pointer;
    border-radius: 3px;
    transition: all ease-in 0.15s;
    img {
      width: 30px;
      height: 30px;
    }
  }

  .owl-prev {
    left: -40px;
    position: absolute;
  }

  .owl-next {
    right: -40px;
    position: absolute;
  }

  .owl-carousel .owl-stage {
    //margin-top: 30px;
  }
  .owl-theme .owl-nav {
    position: absolute;
    top: 50%;
    /* transform: translateY(50%); */
    margin-top: -35px;
    width: 100%;
  }
}

// ChallengeComp Battle Sidebar
.challenge-battle-sidebar {
  &.challenge-box {
    .challenge-header {
      padding: 1rem;
      //border-bottom: 1px solid $gray-slate-300;
      img {
        width: 35px;
      }
    }
    .challenge-content {
      img {
        width: $img-tiny;
        margin: auto;
        margin-bottom: 1rem;
      }
      .challenge-name {
        color: $primary;
      }
    }
    .challenge-footer {
      padding: 1rem;
      border-top: 1px solid $gray-slate-300;
      gap: 14px;
      datnek-ds-button {
        flex: 1;
      }
    }
  }

  .challengers {
    .item {
      position: relative;
      width: 100%;
      border-radius: $round-card;
      padding: 0;
      margin-bottom: 1rem;
      &.left {
        .avatar {
          img {
            left: 0;
          }
        }
      }
      &.right {
        .avatar {
          img {
            right: 0;
          }
        }
      }
      .m-right {
        margin-right: 0;
      }
      .m-left {
        margin-left: 0;
      }
      .avatar {
        position: relative;
        img {
          position: absolute;
          display: block;
          bottom: 0;
          width: 20px;
          height: 20px;
          margin-bottom: 0;
          z-index: 10;
        }
      }
      .infos {
        span {
          color: $gray-300;
        }
      }
    }
  }
}

// ChallengeComp Box
.challenge-box {
  border: 1px solid $gray-slate-300;
  .top-header {
    height: 58px;
    img {
      width: 35px;
      margin-right: 0.6rem;
    }
  }
  .challenge-header {
    padding: $card-padding;
    position: relative;
    min-height: 58px;
    //border-bottom: 1px solid $gray-slate-300;
    img {
      width: $img-small-tiny;
      height: $img-small-tiny;
      object-fit: cover;
    }
  }
  .challenge-content {
    img {
      // margin: auto;
      width: 100%;
      height: 165px;
      object-fit: cover;
    }
    .society {
      border: 1px solid $gray-slate-300;
    }
  }
  .icons {
    display: flex;
    align-items: center;
    position: unset;
    width: 100%;
    gap: 14px;
    datnek-ds-button {
      flex: 1;
    }
  }
  .subtitle {
    color: $gray-300;
    font-weight: 500 !important;
  }

  .timer {
    display: flex;
    width: auto !important;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-left: 1rem;
    text-align: center;
    border-radius: 13px;
    background: #f0f2f5;
  }

  // .dropdown-formly-menu.show {
  //   width: 300px;
  // }
  .datnek-ds-card-footer {
    @media (max-width: 575.98px) {
      flex-wrap: wrap;
    }
  }
}

// ChallengeComp details
.challenge-details {
  &.challenge-box {
    .top-header {
      height: 58px;
      img {
        width: 35px;
        margin-right: 0.6rem;
      }
    }
    .challenge-header {
      padding: 1rem;
      //border-bottom: 1px solid $gray-slate-300;
      img {
        width: $img-tiny;
        height: 45px;
        object-fit: cover;
      }
    }
    .challenge-content {
      &.context {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        margin-left: -16px;
        margin-right: -16px;
        border-bottom: 1px solid $gray-slate-300;
        @media (min-width: 576px) and (max-width: 767.98px) {
          margin-left: 0px;
          margin-right: 0px;
        }
        .society {
          border: 1px solid $gray-slate-300;
        }
        .img {
          img {
            width: 100%;
            height: 137px;
            object-fit: cover;
          }
        }
      }

      .challenge-name {
        color: $primary;
      }
    }
    .icons {
      display: flex;
      align-items: center;
      position: unset;
      //width: 100%;
      //gap: 14px;
      datnek-ds-button {
        flex: 1;
      }
    }
    .challenge-footer {
      padding: 1rem 1.5rem;
      border-top: 1px solid $gray-slate-300;
    }
  }

  .subtitle {
    color: $gray-300;
    font-weight: 500 !important;
  }

  .timer {
    display: flex;
    width: 100% !important;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-left: 1rem;
    text-align: center;
    border-radius: 13px;
    background: #f0f2f5;
  }

  // .dropdown-formly-menu.show {
  //   width: 300px;
  // }

  .left,
  .right {
    width: 50%;
    @media (max-width: 575.99px) {
      width: 100%;
    }
  }
}

// ChallengeComp winners box
.challenge-winners-box {
  &.challenge-box {
    .top-header {
      height: 58px;
      img {
        width: 35px;
        margin-right: 0.6rem;
      }
    }
    .challenge-header {
      padding: 1rem;
      //border-bottom: 1px solid $gray-slate-300;
      img {
        width: $img-tiny;
        height: 45px;
        object-fit: cover;
      }
    }
    .challenge-content {
      &.context {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        margin-left: -16px;
        margin-right: -16px;
        border-bottom: 1px solid $gray-slate-300;
        @media (min-width: 576px) and (max-width: 767.98px) {
          margin-left: 0px;
          margin-right: 0px;
        }
        .society {
          border: 1px solid $gray-slate-300;
        }
        .img {
          img {
            width: 100%;
            height: 200px;
            object-fit: cover;
          }
        }
      }

      .challenge-name {
        color: $primary;
      }
    }
    .icons {
      display: flex;
      align-items: center;
      position: unset;
      width: 100%;
      gap: 14px;
      datnek-ds-button {
        flex: 1;
      }
    }
    .challenge-footer {
      padding: 1rem 1.5rem;
      border-top: 1px solid $gray-slate-300;
    }
  }

  .winners {
    .item {
      position: relative;
      width: 100%;
      border: 1px solid $gray-slate-300;
      border-radius: $round-card;
      padding: 0rem 1rem;
      margin-bottom: 1rem;
      overflow: hidden;
      transition: all ease-in 0.25s;
      @media (max-width: 575.98px) {
        border-radius: 0;
        padding: 1rem;
      }
      &:hover {
        background-color: $home-gray;
      }
      .infos {
        width: 100%;
        @media (max-width: 575.98px) {
          // padding-right: 70px;
          width: 75%;
        }
      }
      .note {
        width: 100%;
        @media (max-width: 575.98px) {
          // padding-right: 70px;
          width: 25%;
        }
      }
      .medal {
        position: absolute;
        right: 19px;
        top: -6px;
        width: auto;
        height: 72px;
        @media (max-width: 575.98px) {
          // padding-right: 70px;
          right: -18px;
          top: -6px;
          width: auto;
          height: 72px;
        }
      }
      .gold {
        position: relative;
        &::before {
          //content: "";
          width: 65px;
          height: 65px;
          border: 2px solid $gold;
          border-radius: 50%;
          position: absolute;
          top: -3px;
          left: -4px;
          display: block;
        }
      }
      .silver {
        position: relative;
        &::before {
          //content: "";
          width: 65px;
          height: 65px;
          border: 2px solid $silver;
          border-radius: 50%;
          position: absolute;
          top: -3px;
          left: -4px;
          display: block;
        }
      }
      .bronze {
        position: relative;
        &::after {
          // content: "";
          width: 65px;
          height: 65px;
          border: 2px solid $bronze;
          border-radius: 50%;
          position: absolute;
          top: -3px;
          left: -4px;
          display: block;
        }
      }
      .indice {
        position: absolute;
        top: 0;
        right: 0;
        width: 15px;
        height: 15px;
        border-radius: $round-full;
        &.gold {
          background-color: $gold;
        }
        &.silver {
          background-color: $silver;
        }
        &.bronze {
          background-color: $bronze;
        }
      }
    }
  }

  .chart {
    position: relative;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    transform: scale(0.7);
    svg {
      width: 70px;
      height: 70px;

      circle {
        fill: none;
        stroke: $primary;
        stroke-width: 3px;
        transform: translate(5px, 5px);
        stroke-dasharray: 190;
      }
    }
    span {
      position: absolute;
      font-size: 1.5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .subtitle {
    color: $gray-300;
    font-weight: 500 !important;
  }

  .timer {
    display: flex;
    width: auto !important;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-left: 1rem;
    text-align: center;
    border-radius: 13px;
    background: #f0f2f5;
  }

  .display-5 {
    color: $gray-300;
  }

  // .dropdown-formly-menu.show {
  //   width: 300px;
  // }
}

// ChallengeComp winners sidebar
.challenge-winners-sidebar {
  &.challenge-box {
    .challenge-header {
      padding: 0.6rem;
      border-bottom: 1px solid $gray-slate-300;
      img {
        width: 35px;
      }
    }
    .challenge-content {
      &.context {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        margin-left: -16px;
        margin-right: -16px;
        border-bottom: 1px solid $gray-slate-300;
        .society {
          border: 1px solid $gray-slate-300;
        }
        .img {
          img {
            width: 100%;
            height: 100px;
            object-fit: cover;
          }
        }
      }
      img {
        width: $img-tiny;
        margin: auto;
      }
      .challenge-name {
        color: $primary;
      }
    }
    .challenge-footer {
      padding: 1rem;
      border-top: 1px solid $gray-slate-300;
      gap: 14px;
      datnek-ds-button {
        flex: 1;
      }
    }
  }

  .infos {
    width: 100%;
    &:hover {
      text-decoration: none;
    }
    .name {
      color: $text;
      position: relative;
      img {
        position: absolute;
        right: -30px;
        width: 12px;
      }
    }
    span {
      color: $gray-300;
    }
  }

  .winners {
    gap: 10px;
    div {
      position: relative;
      &.gold {
        width: 58px;
        margin: auto;
        &::before {
          content: '';
          width: $img-tiny;
          height: 45px;
          border: 2px solid $gold;
          border-radius: 50%;
          position: absolute;
          top: 1px;
          left: 6px;
          display: block;
          transform: scale(1.2);
        }
      }
      &.silver {
        width: 58px;
        margin: auto;
        &::before {
          content: '';
          width: $img-tiny;
          height: 45px;
          border: 2px solid $silver;
          border-radius: 50%;
          position: absolute;
          top: 1px;
          left: 6px;
          display: block;
          transform: scale(1.2);
        }
      }
      &.bronze {
        width: 58px;
        margin: auto;
        &::before {
          content: '';
          width: $img-tiny;
          height: 45px;
          border: 2px solid $bronze;
          border-radius: 50%;
          position: absolute;
          top: 1px;
          left: 6px;
          display: block;
          transform: scale(1.2);
        }
      }
      .indice {
        position: absolute;
        top: 0;
        right: 0;
        width: 15px;
        height: 15px;
        border-radius: $round-full;
        &.gold {
          background-color: $gold;
        }
        &.silver {
          background-color: $silver;
        }
        &.bronze {
          background-color: $bronze;
        }
      }
    }
    .item {
      width: 100%;
    }
  }
}

// My challenge details
.my-challenge-details {
  &.challenge-box {
    .top-header {
      height: 58px;
      img {
        width: 35px;
        margin-right: 0.6rem;
      }
    }
    .challenge-header {
      padding: 1rem;
      //border-bottom: 1px solid $gray-slate-300;
      img {
        width: $img-tiny;
        height: 45px;
        object-fit: cover;
      }
    }
    .challenge-content {
      &.context {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        margin-left: -16px;
        margin-right: -16px;
        border-bottom: 1px solid $gray-slate-300;
        @media (min-width: 576px) and (max-width: 767.98px) {
          margin-left: 0px;
          margin-right: 0px;
        }
        .society {
          border: 1px solid $gray-slate-300;
        }
        .img {
          img {
            width: 100%;
            height: 137px;
            object-fit: cover;
          }
        }
      }

      .challenge-name {
        color: $primary;
      }
    }
    .icons {
      display: flex;
      align-items: center;
      position: unset;
      width: 100%;
      gap: 14px;
      datnek-ds-button {
        flex: 1;
      }
    }
    .challenge-footer {
      padding: 1rem 1.5rem;
      border-top: 1px solid $gray-slate-300;
    }
  }

  .chart {
    position: relative;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    transform: scale(0.7);
    svg {
      width: 70px;
      height: 70px;

      circle {
        fill: none;
        stroke: $primary;
        stroke-width: 3px;
        transform: translate(5px, 5px);
        stroke-dasharray: 190;
      }
    }
    span {
      position: absolute;
      font-size: 1.5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .subtitle {
    color: $gray-300;
    font-weight: 500 !important;
  }

  .timer {
    display: flex;
    width: 100% !important;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-left: 1rem;
    text-align: center;
    border-radius: 13px;
    background: #f0f2f5;
  }

  .display-5 {
    color: $gray-300;
  }

  // .dropdown-formly-menu.show {
  //   width: 300px;
  // }

  .left,
  .right {
    width: 50%;
    @media (max-width: 575.99px) {
      width: 100%;
    }
  }
}

// CHAT MESSAGE LEFT -----------------------------------------
.chat-message-left {
  display: flex;
  position: relative;
  flex-shrink: 0;
  margin-right: auto;
  padding-right: 47px;
  padding-bottom: 1rem;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 1rem;
  @media (max-width: 767.98px) {
    padding-right: 15px;
  }
  &.small {
    padding-right: 15px !important;
    .message-box {
      .message {
        min-height: auto;
        span {
          position: absolute;
          bottom: -22px;
          right: 0;
        }
      }
    }
    .avatar {
      width: 30px;
    }
  }
  .avatar,
  datnek-ds-avatar {
    width: auto; // 45px
  }
  .message-box {
    position: relative;
    min-width: 100px;
    &:hover {
      .like-btn {
        //display: block;
      }
    }
    .message {
      background-color: $white !important;
      color: $text;
      border-radius: $round-card !important;
      box-shadow: 0 0.125rem 0.25rem rgba($dark, 8%) !important;
      width: 100%;
      position: relative;
      min-height: 58px;
      text-align: left;
      padding-right: 1.7rem !important;
      .respond {
        background-color: rgba($dark, 15%);
        padding: 0.5rem;
        border-radius: 8px;
        //font-style: italic;
        position: relative;
        cursor: pointer;
        span {
          position: relative;
          top: 0 !important;
        }
        .name-poster {
          //font-weight: bold;
          //color: $white;
        }
        .close-respond {
          position: absolute;
          right: 0;
          top: 0;
          color: $white;
        }
      }
      span {
        position: absolute;
        bottom: -22px;
        right: 0;
      }
      .liked-icon {
        position: relative;
        cursor: pointer;
        bottom: 0;
        left: 0;
        z-index: 10;
        height: 30px;
        width: max-content;
        border-radius: 15px;
        background: white;
        box-shadow: -1px 0px 5px rgb(0, 0, 0, 16%);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.3rem;
        margin-top: $card-padding;
        gap: 0.4rem;
        img {
          width: 20px;
        }
        span {
          position: relative;
          bottom: unset;
        }
      }
    }
    .like-btn {
      right: 0;
      top: 0;
      position: absolute;
      opacity: 1;
      datnek-ds-button-icon {
        position: absolute;
        right: -33px;
        top: 0;
      }
      .likes-icons {
        display: none;
        position: absolute;
        gap: 10px;
        bottom: -115px;
        left: -186px;
        padding: 16px;
        cursor: pointer;
        border-radius: $round-card;
        z-index: 1000;
        @media (max-width: 575.98px) {
          left: -150px;
        }
        &.grid-icons {
          display: flex !important;
          width: 212px;
          overflow: hidden;
          datnek-ds-button-icon {
            position: relative !important;
            right: 0 !important;
            top: 0 !important;
          }
          &.full-grids {
            display: grid !important;
            width: auto !important;
            grid-template-columns: repeat(4, 1fr);
            //transform: translateY(90px);
          }
        }
        img {
          transition: all ease-in 0.18s;
          &:hover {
            transform: scale(1.5);
            transform-origin: center;
          }
        }
      }
    }
  }
  .message-image-box {
    position: relative;
    width: fit-content;
    span {
      position: absolute;
      bottom: -22px;
      left: 0;
    }
  }
  .message-audio {
    position: relative;
    width: 100%;
    audio {
      width: 290px !important;
      margin-left: 0 !important;
    }
    span {
      position: absolute;
      bottom: -22px;
      left: 0;
    }
  }

  .options {
    position: absolute;
    right: -30px;
    top: 0;
    .dropdown-menu.show {
      display: block;
      transform: translate(-170px, 33px) !important;
      width: 200px;
    }
    .dropdown-item {
      font-size: 0.8rem !important;
    }
  }
  @media (max-width: 575.98px) {
    nk-shared-likes-icons-popbox {
      position: relative;
      left: -90px !important;
    }
  }

  audio::-webkit-media-controls-panel {
    background: white;
  }

  .p-tabview .p-tabview-panels {
    border-bottom-right-radius: $round-card;
    border-bottom-left-radius: $round-card;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-width: 0 0 2px 0 !important;
    border-color: $primary !important;
    font-size: 0.8rem;
    padding: 0 !important;
    .head-react {
      height: 25px;
      img {
        transform: scale(0.7);
      }
    }
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border-width: 0 0 2px 0 !important;
    border-color: transparent !important;
    font-size: 0.8rem;
    padding: 0 !important;
    .head-react {
      height: 28px;
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      img {
        width: 20px;
      }
    }
  }

  .p-tabview .p-tabview-nav li {
    transform: translateY(-2px);
  }

  .p-tabview .p-tabview-nav {
    background: #ffffff;
    border: 0px solid #dee2e6;
    border-width: 0 0 1px 0;
  }

  .head-react {
    height: 28px;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    img {
      width: 20px;
    }
  }
  .dropdown-reactions {
    .dropdown-menu {
      min-width: 17rem !important;
      //max-width: 19rem;
      border-radius: $round-card !important;
      padding: 0 !important;
      overflow: hidden;
      @media (max-width: 575px) {
        min-width: 100% !important;
        max-width: 100% !important;
        border-radius: 0.45rem 0.45rem 0 0 !important;
        position: fixed !important;
        left: 0 !important;
        bottom: 0 !important;
        top: unset !important;
        transform: translate(0px, 0px) !important;
        height: 210px;
        width: 100%;
      }
      .p-tabview-nav-link {
        padding: 0.5rem 0.7rem !important;
      }
    }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
      padding: 0.8rem 0.5rem !important;
    }
    .p-tabview .p-tabview-panels {
      padding: 0 !important;
    }
    .list-react {
      height: 160px;
      //overflow-y: scroll;
      .left {
        width: 85%;
      }
      .right {
        width: 15%;
        img {
          width: 20px;
        }
      }
      .infos {
        span {
          position: relative;
          color: $gray-300;
          bottom: auto;
        }
      }
    }
  }

  .article-box,
  .group-box {
    border-radius: $round-card;
    overflow: hidden;
    background-color: $secondary;
    max-width: 300px;
    @media (max-width: 767.98px) {
      width: 100%;
    }
    .img {
      width: 100%;
      height: 160px;
      img {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }
  }

  .p-sidebar .p-sidebar-header {
    padding: 0rem !important;
  }

  .p-sidebar-bottom {
    height: 13rem !important;
  }
}

// CHAT MESSAGE RIGHT ----------------------------------------
.chat-message-right {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  margin-left: auto;
  flex-shrink: 0;
  padding-left: 47px;
  padding-bottom: 1rem;
  align-items: flex-start;
  margin-bottom: 1rem;
  gap: 16px;
  @media (max-width: 767.98px) {
    padding-left: 30px;
  }
  &.small {
    padding-left: 15px !important;
  }
  .avatar {
    width: $img-tiny;
  }
  .message-box {
    position: relative;
    min-width: 100px;
    .message {
      background-color: $primary !important;
      color: white;
      border-radius: $round-card !important;
      box-shadow: 0 0.125rem 0.25rem rgba($dark, 8%) !important;
      width: 100%;
      position: relative;
      min-height: 58px;
      text-align: right;
      padding-left: 1.7rem !important;
      .respond {
        background-color: rgba($dark, 15%);
        padding: 1rem;
        border-radius: 8px;
        //font-style: italic;
        position: relative;
        cursor: pointer;
        span {
          position: relative;
          top: 0 !important;
        }
        .name-poster {
          //font-weight: bold;
          color: $white;
        }
      }
      span {
        position: absolute;
        bottom: -22px;
        right: 0;
      }
      .liked-icon {
        position: relative;
        cursor: pointer;
        bottom: 0;
        left: 0;
        z-index: 10;
        height: 30px;
        width: max-content;
        border-radius: 15px;
        background: white;
        box-shadow: -1px 0px 5px rgb(0, 0, 0, 16%);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.3rem;
        margin-top: $card-padding;
        gap: 0.4rem;
        img {
          width: 20px;
        }
        span {
          position: relative;
          bottom: unset;
        }
      }
    }
    .like-btn {
      left: 0;
      top: 0;
      position: absolute;
      opacity: 1;
      datnek-ds-button-icon {
        position: absolute;
        left: -40px;
        top: 0;
      }
      .likes-icons {
        display: none;
        position: absolute;
        gap: 10px;
        bottom: -115px;
        left: -75px;
        padding: 16px;
        cursor: pointer;
        border-radius: $round-card;
        z-index: 1000;
        @media (max-width: 575.98px) {
          left: -65px;
        }
        &.grid-icons {
          display: flex !important;
          width: 212px;
          overflow: hidden;
          datnek-ds-button-icon {
            position: initial !important;
            right: 0 !important;
            top: 0 !important;
          }
          &.full-grids {
            display: grid !important;
            width: auto !important;
            grid-template-columns: repeat(4, 1fr);
            //transform: translateY(90px);
          }
        }
        img {
          transition: all ease-in 0.18s;
          &:hover {
            transform: scale(1.5);
            transform-origin: center;
          }
        }
      }
    }
  }
  .message-image-box {
    position: relative;
    width: fit-content;
    span {
      position: absolute;
      bottom: -22px;
      left: 0;
    }
  }
  .message-audio {
    position: relative;
    margin-left: -20px;
    audio {
    }
    span {
      position: absolute;
      bottom: -22px;
      left: 0;
    }
  }

  .options {
    position: absolute;
    left: -30px;
    top: 0;
    .dropdown-menu.show {
      display: block;
      transform: translate(0px, 25px) !important;
      width: 200px;
      @media (max-width: 575.98px) {
        transform: translate(-25px, 33px) !important;
      }
    }
    .dropdown-item {
      font-size: 0.8rem !important;
    }
  }

  @media (max-width: 575.98px) {
    nk-shared-likes-icons-popbox {
      position: relative;
      left: 45px !important;
    }
  }

  audio::-webkit-media-controls-panel {
    background: white;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-width: 0 0 2px 0 !important;
    border-color: $primary !important;
    font-size: 0.8rem;
    padding: 0 !important;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border-width: 0 0 2px 0 !important;
    border-color: transparent !important;
    font-size: 0.8rem;
    padding: 0 !important;
  }

  .p-tabview .p-tabview-nav li {
    transform: translateY(-2px);
  }

  .p-tabview .p-tabview-nav {
    background: #ffffff;
    border: 0px solid #dee2e6;
    border-width: 0 0 1px 0;
  }

  .head-react {
    height: 28px;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    img {
      width: 20px;
    }
  }
  .dropdown-reactions {
    width: 100%;
    .dropdown-menu {
      min-width: 17rem !important;
      max-width: 19rem;
      border-radius: $round-card !important;
      padding: 0 !important;
      overflow: hidden;
      @media (max-width: 575px) {
        min-width: 100% !important;
        max-width: 100% !important;
        border-radius: 0.45rem 0.45rem 0 0 !important;
        position: fixed !important;
        left: 0 !important;
        bottom: 0 !important;
        top: unset !important;
        transform: translate(0px, 0px) !important;
        height: 210px;
        width: 100%;
      }
      .p-tabview-nav-link {
        padding: 0.5rem 0.7rem !important;
      }
    }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
      padding: 0.8rem 0.5rem !important;
    }
    .p-tabview .p-tabview-panels {
      padding: 0 !important;
    }
    .list-react {
      height: 160px;
      //overflow-y: scroll;
      .left {
        width: 85%;
      }
      .right {
        width: 15%;
        img {
          width: 20px;
        }
      }
      .infos {
        span {
          position: relative;
          color: $gray-300;
          bottom: auto;
        }
      }
    }
  }

  .p-sidebar .p-sidebar-header {
    padding: 0rem !important;
  }

  .p-sidebar-bottom {
    height: 13rem !important;
  }
}

.card {
  margin-bottom: 1rem;
  border-bottom: 1px solid #dfdfdf !important;
  .card-header {
    background-color: $white;
  }
}

// CHATBOX ----------------------------------------------
// Card user box
.card-user-box {
  &.user-box {
    border-radius: $round-card;
    // overflow: hidden;
    &.chat {
      .header {
        height: 70px;
      }
      .content {
        .avatar {
          margin-top: -30px;
        }
      }
    }
    .header {
      height: 100px;
      width: 100%;
      overflow: hidden;
      border-radius: $round-card $round-card 0 0;
      position: relative;
      datnek-ds-button-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
      }
      img {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }
    .content {
      text-align: center;
      .avatar {
        display: flex;
        justify-content: center;
        position: relative;
        margin-top: -40px;
        datnek-ds-button-icon {
          position: absolute;
          top: 20px;
          left: 45%;
          z-index: 1;
        }
      }
      .awards {
        gap: 6px;
        img {
          height: 20px;
        }
      }
      .infos {
        padding: 1rem;
      }
      .competences {
        padding: 0 1.5rem 1.5rem;
        height: 60px;
        overflow: hidden;
        &.open {
          height: auto;
        }
        span {
          color: $gray-300;
        }
        .readmore {
        }
      }
      .stats {
        padding: 1.5rem 0rem;
        border-top: 1px solid $gray-slate-300;
        .datas {
          .left,
          .right,
          .center {
            width: 100%;
          }
          .left,
          .center {
            border-right: 1px solid $gray-slate-300;
          }
        }
      }
      .footer {
        padding: 1rem;
        border-top: 1px solid $gray-slate-300;
      }
      .challenge-footer {
        padding: 1rem;
        border-top: 1px solid $gray-slate-300;
        gap: 14px;
        datnek-ds-button,
        .share-post {
          flex: 1;
        }
      }
    }
  }
  .management {
    border: $round-card;
    border: 1px solid $gray-slate-300;
  }

  // :host ::ng-deep .share-post .dropdown-formly-menu.show {
  //   transform: translate(-160px, 40px) !important;
  //   width: 295px !important;
  // }
}

// Chat call
.chat-call {
  .container-fluid {
    height: calc(100vh - 170px);
    top: 0;
  }

  .call-header {
    height: 45px;
    width: 100%;
    border-bottom: 1px solid lightgrey;
  }

  .call-body {
    height: calc(100vh - 169px);
    overflow: hidden;
    background-color: $home-gray;
    z-index: 3;
    .users {
      width: 100%;
      background-color: $white;
      height: 100%;
      padding: 0 1rem 0 2rem;
    }
    .messages {
      width: 100%;
      background-color: $white;
      height: 100%;
    }
    .user-grid {
      display: grid;
      // grid-template-columns: repeat(2, 1fr);
      // grid-template-rows: repeat(2, 16vw);
      height: calc(100vh - 170px);
      align-items: center;
      grid-gap: 0px;
      width: 100%;
      .user {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: inset 0px 0px 0px 4px $gray-slate-300;
        padding: 2rem;
        z-index: 1;
        &.active {
          box-shadow: inset 0px 0px 0px 4px $primary;
        }
      }
      &.one-user {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        .user {
          &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 3;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      &.two-user {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 1fr);
        .user {
          &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
          }
          &:nth-child(2) {
            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
          }
        }
      }
      &.three-user {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        // .user {
        //   &:nth-child(1) {
        //     grid-column-start: 1;
        //     grid-column-end: 2;
        //     grid-row-start: 1;
        //     grid-row-end: 2;
        //   }
        //   &:nth-child(2) {
        //     grid-column-start: 2;
        //     grid-column-end: 2;
        //     grid-row-start: 1;
        //     grid-row-end: 2;
        //   }
        // }
      }
      &.four-user {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        .user {
          &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
          }
          &:nth-child(2) {
            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
          }
          &:nth-child(3) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 2;
          }
          &:nth-child(4) {
            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 2;
          }
        }
      }
      &.five-user {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        .user {
          &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 4;
          }
          &:nth-child(2) {
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 4;
          }
          &:nth-child(3) {
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 2;
          }
          &:nth-child(4) {
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 2;
            grid-row-end: 3;
          }
          &:nth-child(5) {
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 3;
            grid-row-end: 4;
          }
        }
      }
    }
  }
  .call-footer {
    border-top: 1px solid $gray-300;
    height: 65px;
    position: fixed;
    width: 100%;
  }

  .icons {
    position: fixed;
    top: 107px;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 2;
    padding: 0.7rem;
  }

  .sidebar-chat {
    max-height: calc(100vh - 410px);
    //overflow-y: scroll;
    padding-top: 12px;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

// Chat list box
.chat-list-box {
  display: block;
  position: fixed;
  bottom: 0;
  right: 16px;
  z-index: 12;
  width: 350px;
  height: 530px;
  border-radius: $round-card $round-card 0 0;
  overflow: hidden;
  transform: translateY(530px);
  transition: transform ease-in-out 0.5s;
  @media (max-width: 767.98px) {
    width: 100%;
    right: 0;
    height: 100%;
    border-radius: 0;
    transform: translateY(1830px);
  }
  &.show {
    transform: translateY(55px);
  }
  .header-chat-list {
    background-color: $primary;
    color: $white;
    height: 56px;
    .display-4 {
      color: $white;
    }
  }
  .body-chat-list {
    background-color: $white;
    height: inherit;
    overflow-y: scroll;
    padding-bottom: 4rem !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    padding-bottom: 4rem !important;
    .user-chat-box {
      padding-top: 0 !important;
      @media (max-width: 991.98px) {
        width: 95%;
      }
    }
  }
  .p-component {
    height: 530px !important;
  }

  @media (max-width: 767.98px) {
    .p-component {
      height: calc(100vh - 225px) !important;
    }
  }

  .p-scrollpanel-content {
    box-sizing: border-box;
    height: 530px !important;
    overflow: auto;
    padding: 0 0 18px 0;
    position: relative;
    width: calc(100% + 20px) !important;
  }
}

.float-chat-button {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 10;
  width: 50px;
  height: 50px;
  border-radius: $round-full;
  background-color: $white;
  cursor: pointer;
  .have-messages {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: $success;
    position: absolute;
    top: -2px;
    right: 0px;
  }
}

// Chat list box message
.chat-list-box-message {
  &.chat-messages {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 147px);
    overflow-y: scroll;
    background-color: $home-gray;
    border-radius: $round-card;
    padding: 1rem 1rem 6rem 1rem;
    // HIDE SCROLL
    overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .date-message {
      position: relative;
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $gray-300;
        display: block;
        top: 10px;
        left: 0;
        z-index: 0;
      }
      span {
        background-color: $home-gray;
        padding: 0 1rem;
        z-index: 1;
        display: inline-block;
        position: relative;
        width: auto;
      }
    }
  }

  .chat-form {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: $white;
    z-index: 4;
    .icons {
      width: 115px;
    }
    .input {
      width: 100%;
      gap: 16px;
    }
  }
}

// Chat popup box
.chat-popup-box {
  height: 475px;
  border-radius: $round-card $round-card 0 0;
  overflow: hidden;
  transform: translateY(350px);
  display: block;
  position: fixed;
  width: 350px;
  z-index: 12;
  bottom: 0;
  transition: transform ease-in-out 0.5s;
  &.chat-popup {
    @media only screen and (max-width: 540px) {
      display: none !important;
    }
  }
  &.sidebar {
    display: block;
    position: relative;
    bottom: 0;
    right: 0;
    z-index: 12;
    width: 100%;
    height: auto;
    border-radius: 0;
    transform: translateY(0);
    .chat-messages {
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 147px);
      overflow-y: scroll;
      background-color: #f0f2f5;
      border-radius: 0;
      padding: 1rem 1.5rem 6rem 1.5rem;
      overflow-y: scroll;
      -ms-overflow-style: none !important;
      scrollbar-width: none;
      width: 96%;
    }
    .chat-form {
      position: fixed;
      width: 96%;
      bottom: 86px;
      background-color: #ffffff;
      z-index: 4;
      padding: 0.6rem;
      .icons .all-icons {
        position: fixed;
        width: 100%;
        bottom: 154px;
        left: 0;
        background: white;
        justify-content: space-around;
        display: flex;
        padding: 12px;
        border-bottom: 1px solid #b9b9b9;
      }
    }
  }
  @media (max-width: 575.99px) {
    width: 100%;
    right: 0;
    height: 100%;
    border-radius: 0;
    transform: translateY(1830px);
  }
  &.show {
    transform: translateY(0px);
  }
  .header-chat-list {
    background-color: $primary;
    color: $white;
    height: 56px;
  }
  .body-chat-list {
    background-color: $white;
    height: inherit;
    overflow-y: scroll;
    padding-bottom: 4rem !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .chat-messages {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 147px);
    overflow-y: scroll;
    background-color: $home-gray;
    border-radius: $round-card;
    padding: 1rem 1rem 6rem 1rem;
    // HIDE SCROLL
    overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .date-message {
      position: relative;
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $gray-300;
        display: block;
        top: 10px;
        left: 0;
        z-index: 0;
      }
      span {
        background-color: $home-gray;
        padding: 0 1rem;
        z-index: 1;
        display: inline-block;
        position: relative;
        width: auto;
      }
    }
    datnek-ds-svg-icon {
      // position: absolute;
      // left: 45px;
      // bottom: 22px;
      // transform: scale(0.8);
    }
  }

  .chat-form {
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: $white;
    z-index: 100000009;
    .icons {
      width: 25px;
      position: relative;
      .all-icons {
        position: fixed;
        width: 100%;
        bottom: 68px;
        background: white;
        justify-content: space-around;
        display: flex;
        padding: 12px;
        border-bottom: 1px solid $gray-300;
      }
    }
    .input {
      width: 100%;
      //gap: 16px;
      datnek-ds-editor-add-comments {
        width: 100% !important;
      }
    }
    .respond-message {
      background-color: $gray-slate-200;
      border-radius: $round-card;
      position: relative;
      .close {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}

// GALLERY -----------------------------------------------------
// Adaptative image gallery
.gallery-publication {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 19vw);
  grid-gap: 10px;
  margin-left: -$card-padding !important;
  margin-right: -$card-padding !important;
  @media (max-width: 767.98px) {
    grid-template-rows: repeat(2, 25vw);
  }
  figure {
    margin-bottom: 0 !important;
    &.last-img {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb($primary, 63%);
        z-index: 1;
        cursor: pointer;
      }
      &::after {
        content: attr(rest);
        position: absolute;
        display: flex;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        width: 40px;
        height: 40px;
        color: white;
        font-size: 2rem;
        font-weight: 700;
        z-index: 2;
        cursor: pointer;
      }
    }
    &:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 3;
    }

    .gallery__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }
  &.one-img {
    figure {
      &:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
      }
    }
  }
  &.two-img {
    figure {
      &:nth-child(2) {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
      }
    }
  }
  &.four-img {
    figure {
      &:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &:nth-child(2) {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
      }
    }
  }
}

// Image gallery
.image-gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  //grid-template-rows: repeat(2, 16vw);
  grid-gap: $small-gap;
  position: relative;
  &.is-group-gallery {
    grid-gap: $gap;
    figure {
      height: 125px;
      @media (max-width: 575.98px) {
        height: 87px;
      }
    }
  }
  figure {
    width: 100%;
    height: 60px;
    margin-bottom: 0;
    &.last-img {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb($primary, 63%);
        z-index: 1;
        cursor: pointer;
        border-radius: 6px;
      }
      &::after {
        content: attr(rest);
        position: absolute;
        display: flex;
        top: 50%;
        left: 50%;
        transform: translate(-40%, -30%);
        width: 40px;
        height: 40px;
        color: white;
        font-size: 1.5rem;
        font-weight: 500;
        z-index: 2;
        cursor: pointer;
      }
    }

    .gallery__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
      border-radius: 6px;
    }
  }
  .icon-close {
    position: absolute;
    right: 0;
    top: 0;
  }
}

// Document gallery
.document-gallery {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  .document-item {
    width: 100%;
    transition: all ease-in 0.2s;
    &:hover {
      background-color: $gray-slate-300;
      border-radius: 6px;
    }
    .document-name {
      position: relative;
      bottom: unset;
    }
  }
  .block {
    position: relative;
    width: 100%;
    transition: all ease-in 0.15s;
    &:hover {
      datnek-ds-button-icon {
        display: block;
      }
    }
    datnek-ds-button-icon {
      position: absolute;
      display: none;
    }
    img {
      height: 34px;
      margin: auto;
    }
  }
}

// Video gallery
.video-gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  //grid-template-rows: repeat(2, 16vw);
  grid-gap: $small-gap;
  position: relative;
  &.is-group-gallery {
    grid-gap: $gap;
    figure {
      height: 125px;
      @media (max-width: 575.98px) {
        height: 87px;
      }
    }
  }
  figure {
    width: 100%;
    height: 73px;
    margin-bottom: 0;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    .play {
      width: 40px;
      position: absolute;
      z-index: 2;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      background-color: rgba($text, 0.5);
      z-index: 1;
      border-radius: 6px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
      border-radius: 6px;
    }
    .icon-close {
      position: absolute;
      right: 0;
      top: 0;
    }

    video {
      width: 100%;
      height: 100%;
      transform: scale(1.8);
    }
  }
}

// HEADER --------------------------------------------------------
// Header menu
.dc-header-menu {
  height: 55px;
  position: fixed;
  z-index: 10;
  width: 100%;
  margin: 0 !important;
  background: $white;
  top: 0;
  .menu-icon {
    width: 100%;
  }
  .logo {
    .web {
      width: 140px;
    }
    .mobile {
      width: 25px;
    }
  }
  .input-search-topbar {
    position: fixed !important;
    z-index: 12;
    background: white;
    height: 50px;
    width: 100%;
    input[type='text'] {
      height: 58px;
      margin-top: -5px;
      font-size: 15px;
      display: inline-block;
      border: none;
      outline: none;
      color: #555;
      padding: 0rem 4rem;
      width: 100vw;
      background: none;
      z-index: 3;
      transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
      cursor: text;
      @media (max-width: 560px) {
        padding: 0rem 1rem;
        font-size: 16px;
        margin-top: -4px;
      }
    }
    datnek-ds-button-icon {
      position: absolute;
      right: 5px;
      top: 6px;
    }
  }
  .searchbox {
    background-color: $white;
    position: absolute;
    right: 16px;
    top: 0;
    width: 100%;
    height: -webkit-fill-available;
    z-index: 1;
    @media (max-width: 767.98px) {
      top: 0;
      padding: 5px 7px;
      width: 100%;
      right: 0;
      height: 100%;
    }
    datnek-ds-search-input {
      width: 100%;
    }
  }
  .icon-apps {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: $bg-apps;
  }
  @media (min-width: 1400px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl,
    .container-xxl {
      max-width: 1370px !important;
    }
  }
}

// App header
.app-header {
  .icon-apps {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: $bg-apps;
  }

  .app-menu {
    a {
      text-align: left;
    }
    .item-social {
      cursor: pointer;
      transition: all 0.15s ease-in;
      .img {
        width: 10%;
        height: auto;
        overflow: unset;
        margin-right: 0;
        img {
          width: 100%;
        }
      }
      .infos {
        width: 90%;
        margin-left: 15px;
      }
      &:hover {
        background-color: $secondary;
        padding: 5px;
        border-radius: ($round-card/2);
      }
    }
  }

  .dropdown-menu-block {
    &.dropdown-menu.show {
      width: 600px !important;
      position: absolute !important;
      left: 0px !important;
      top: 5px !important;
      height: fit-content !important;
      background: #f8f9fa;
      overflow: hidden;
      box-shadow: 11px 20px 30px 0px rgb(0, 0, 0, 29%) !important;
      transform: translate(-560px, 35px) !important;
      &.mobile-apps {
        position: fixed;
        transform: translate(0px, 0px) !important;
        width: 100%;
        height: 100% !important;
        z-index: 99999999;
      }
      @media (max-width: 575.98px) {
        position: fixed !important;
        transform: translate(0px, 0px) !important;
        width: 100% !important;
        height: 100% !important;
        z-index: 99999999;
        padding: 0;
        margin: 0;
        top: 0 !important;
        overflow-y: scroll;
      }
      .close {
        position: absolute;
        right: 8px;
        top: 8px;
        z-index: 10;
      }
      .dc-card {
        padding: 9px;
        &.dc-menu-vertical {
          .dc-btn {
            padding: 2px 5px !important;
            // overflow-wrap: break-word;
            // word-break: break-all;
            .dc-btn-img img {
              width: 100%;
              text-align: center;
              margin: auto;
            }
            span {
            }
            img {
              height: 22px;
              float: left !important;
            }
          }
        }
      }
    }
  }

  .p-scrollpanel.p-component {
    @media (max-width: 767.98px) {
      height: auto !important;
    }
    height: auto !important;
  }

  .p-scrollpanel-content {
    height: 0 18px 8px 0 !important;
  }
}

// NOTIFICATION BOX ---------------------------------------------------------
.notif-box {
  padding: $card-padding;
  .header {
    position: relative;
    height: 58px;
    img {
      width: 25px;
    }
    datnek-ds-button-icon {
      position: absolute;
      right: 10px;
      top: 8px;
    }
  }
  .content {
    img {
      margin: auto;
      width: 50px;
    }
  }
  .icons {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 14px;
    datnek-ds-button {
      flex: 1;
    }
  }
}

// SUGGESTION BOX
.suggestion-box {
  .suggestion-header {
    padding: 1rem;
    border-bottom: 1px solid $gray-slate-300;
  }
  .suggestion-content {
    max-height: 400px;
    overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .suggestion-footer {
    padding: 1rem 1.5rem;
    border-top: 1px solid $gray-slate-300;
  }
}

// SUGGESTION-LIST =============================================
.suggestion-list {
  .infos {
    .name {
      img {
        width: unset !important;
        height: unset !important;
      }
    }
  }
}

// USER CHAT LIST ==============================================
.user-chat-box {
  padding-top: 3rem;
  &.pinned {
    padding-top: 0;
  }
  .search-input {
    position: absolute;
    width: 94%;
    top: 0;
    z-index: 10;
  }
}

// SUGGESTION BOX
.invitation-box {
  .suggestion-content {
    max-height: 400px;
  }
}

// FRIENDS =====================================================
.friends {
  .suggestion-box {
    .suggestion-content {
      max-height: unset !important;
    }
  }
}

// SUGGESTION ARTICLE LIST
.suggestion-article-list.home {
  width: 100%;
  .infos {
    width: 100%;
    background-color: $white;
    .title {
      //padding-left: 2.5rem;
    }
    img {
      width: 56px;
      height: 56px;
      object-fit: cover;
      border-radius: 6px;
    }
    &:hover {
      background-color: $white;
    }
    &.full {
      .left-infos {
        width: 100%;
        .actions {
          position: relative;
          @media (max-width: 575.98px) {
          position: absolute !important;
          top: 0;
          right: 0;
          }
        }
        @media (max-width: 575.98px) {
          width: 100%;
        }
        @media (min-width: 576px) and (max-width: 767.98px) {
          width: 80%;
        }

        .content-infos {
          margin-top: -44px;
          @media (max-width: 575.98px) {
            margin-top: 108px;
          }
        }

        .point {
          position: relative;
          top: -9px;
          font-size: xx-large;
          margin: 0 0.3rem;
        }
        .date {
          color: $gray-400;
        }
        .tag {
          font-size: 0.8rem;
          min-width: -moz-fit-content;
          min-width: fit-content;
          background-color: $home-gray;
          padding: 0rem 0.8rem;
          border-radius: 26px;
          display: flex;
          align-items: center;
          height: 30px;
          margin-right: 1rem;
          cursor: pointer;
          &:hover {
            background-color: $gray-300;
          }
          @media (max-width: 575.98px) {
            height: 25px;
            margin-right: 0.3rem;
            margin-bottom: 0.4rem;
          }
        }
        .actions {
          position: unset;
          margin-top: unset;
          margin-left: unset;
          top: unset;
        }
      }
      img {
        width: 116px;
        height: 116px;
        @media (max-width: 575.98px) {
          width: 100% !important;
          border-radius: 0;
          height: 112px;
          margin-left: -16px;
          margin-right: -16px;
          /* top: 0; */
          /* left: 0; */
          position: absolute;
        }
      }

      &.sidebar {
        .left-infos {
          img {
            width: 52px;
            height: 52px;
          }
        }
      }

      .dropdown-menu.show {
        transform: translate(-166px, 35px) !important;
      }
    }
  }
}

// TOP JOBS
.top-jobs {
  .header-top-jobs {
    padding: 1rem;
    border-bottom: 1px solid $gray-slate-300;
  }
  .footer-top-jobs {
    padding: 1rem;
    border-top: 1px solid $gray-slate-300;
  }
}

// ACCORDION =========================================================
.network-accordion {
  background-color: $white;
  color: #222;
  .title {
    background-color: $white;
    width: 100%;
    padding: $card-padding;
    cursor: pointer;
    border-bottom: 1px solid $gray-300;
    transition: all ease-in 0.5s;
    &:hover {
      background-color: $home-gray;
    }
  }
  .body {
    padding: 10px;
    &.hidden {
      display: none;
    }
  }
}

// VOICE RECORDER
.datnek-ds-voice-recorder {
  width: 100%;
  height: 250px;
  background-color: $gray-slate-200;
  position: relative;
  transition: all ease-in 0.2s;
  @media (max-width: 575.98px) {
    height: calc(100% - 108px);
  }
  .record {
    width: 100%;
    &:hover {
      background-color: $gray-slate-300;
    }
    .player {
      width: 100%;
      height: 100px;
      border-radius: $round-full;
      // background-color: $primary;
      @media (max-width: 575.98px) {
        height: 50vh;
      }
      img {
        width: 52px;
      }
    }
  }
  .upload-content {
    width: 100%;
    position: relative;
    @media (max-width: 575.98px) {
      height: 50vh;
    }
    &:hover {
      background-color: $gray-slate-300;
    }
    height: inherit;
    img {
      width: 50px;
      margin: 0 auto;
    }
    .display-2 {
      color: $primary;
    }
    p {
      color: $gray-400;
    }
    input {
      opacity: 0;
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }
}

.message-audio audio {
  margin-left: -20px;
}

audio::-webkit-media-controls-panel {
  background: white;
}

/********** MODAL DESIGN FILE **********/

// Show-Gallery
.show-gallery {
  &.gallery-publication-lightbox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    margin-top: 0;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 100;
    @media (max-width: 767.98px) {
      flex-direction: column;
    }
    .lightbox-view {
      height: 100vh;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all ease-in 0.75s;
      @media (max-width: 767.98px) {
        width: 100%;
        height: 50vh;
      }
      &:hover {
        datnek-ds-button-icon {
          display: block;
          opacity: 1;
        }
      }
      .count {
        position: fixed;
        bottom: 16px;
        //right: 16px;
        color: $text;
        opacity: 1;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        background: rgba($gray-300, 70%);
        padding: 0.4rem 1rem;
        border-radius: 20px;
        @media (max-width: 767.98px) {
          position: absolute;
        }
      }
      .close-btn {
        position: fixed;
        top: 16px;
        left: 16px;
        padding: 0;
        z-index: 2;
      }
      .zoomin-btn {
        position: absolute;
        top: 16px;
        right: 20px;
        padding: 0;
        z-index: 2;
        cursor: pointer;
      }
      .zoomout-btn {
        position: absolute;
        top: 16px;
        right: 70px;
        padding: 0;
        z-index: 2;
        cursor: pointer;
      }
      .favorite-btn {
        position: absolute;
        top: 16px;
        right: 125px;
        padding: 0;
        z-index: 2;
        cursor: pointer;
      }
      .lightbox-img {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        height: auto;
        width: auto;
        h5 {
          position: absolute;
          bottom: 20px;
        }
        img {
          width: 80%;
          height: -webkit-fill-available;
          border-radius: 0.35rem;
        }
      }
      .lightbox-carousel {
        &.btn-prev,
        &.btn-next {
          position: absolute;
          top: 50%;
          @media (max-width: 767.98px) {
            position: absolute;
          }
        }
        &.btn-prev {
          left: 16px;
        }
        &.btn-next {
          right: 16px;
          @media (max-width: 767.98px) {
            right: 16px;
          }
        }
      }
    }
    .comments-view {
      height: 100vh;
      width: 30%;
      position: relative;
      @media (max-width: 767.98px) {
        width: 100%;
        height: 50vh;
      }
    }
  }

  datnek-ds-button-icon {
    display: none;
    opacity: 0;
  }
}

// ChallengeComp Show Winner
.challenge-show-winner {
  @media (max-width: 575.98px) {
    overflow: scroll;
    height: calc(100vh - 55px);
  }

  .content-modal {
    //max-height: 341px;
    max-height: 450px;
    //overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .content-head {
      border-top: 1px solid $gray-slate-300;
      border-bottom: 1px solid $gray-slate-300;
    }
  }

  .p-scrollpanel {
    height: fit-content !important;
  }

  .user-box {
    overflow: hidden;
    background: $home-gray; //url("/assets/Img/bg/bg-placeholder.svg")
    background-size: cover;
    padding: 1rem !important;
    display: block;
    &.chat {
      .header {
        height: 70px;
      }
      .content {
        padding-top: 2.3rem;
        .avatar {
          margin-top: -30px;
        }
      }
    }
    .header {
      height: 100px;
      width: 100%;
      overflow: hidden;
      img {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }
    .content {
      text-align: center;
      margin-bottom: 0;
      .avatar {
        display: flex;
        justify-content: center;
      }
      .infos {
        padding: 1.5rem;
      }
      .competences {
        padding: 0 1.5rem 1.5rem;
        height: 60px;
        overflow: hidden;
        &.open {
          height: auto;
        }
        span {
          color: $gray-300;
        }
        .readmore {
        }
      }
      .stats {
        padding: 1.5rem 0.5rem;
        border-top: 1px solid $gray-slate-300;
        .datas {
          .left,
          .right,
          .center {
            width: 100%;
          }
          .left,
          .center {
            border-right: 1px solid $gray-slate-300;
          }
        }
      }
      .footer {
        padding: 1.5rem;
        border-top: 1px solid $gray-slate-300;
        datnek-ds-link,
        datnek-ds-button {
          flex: 1;
        }
      }
    }
  }

  .scores {
    .title-score {
      border-bottom: 1px solid $gray-slate-300;
    }
    .challenge-name {
      color: $primary;
    }
    .score-items {
      padding: 1rem;
      border-bottom: 1px solid $gray-slate-300;
      .title {
        width: 50%;
        font-weight: 500 !important;
      }
      .jauge {
        width: 30%;
        height: $h-progressbar;
        background-color: $gray-300;
        overflow: hidden;
        position: relative;
        border-radius: 2px;
        .bar {
          position: absolute;
          height: inherit;
          background-color: $primary;
        }
      }
      .note {
        width: 20%;
      }
    }
    .message-jury {
      //background-color: $home-gray;
      border-top: 1px solid $gray-slate-300;
      img {
        width: 30px;
      }
    }
  }

  .title-score {
    border-bottom: 1px solid $gray-slate-300;
  }

  .other-scores {
    overflow-x: scroll;
    display: flex;
    height: 400px;
    width: calc(100vw - 620px);
    overflow-y: scroll;
    //gap: 1rem;
    //grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    .score-item {
      width: 250px;
      padding: 1rem;
      /* border-left: 1px solid lightgray; */
      border-right: 1px solid $gray-slate-300;
      .score-head {
        border-bottom: 1px solid $gray-slate-300;
      }
    }
    .chart {
      position: relative;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      transform: scale(0.7);
      svg {
        width: 70px;
        height: 70px;

        circle {
          fill: none;
          stroke: $primary;
          stroke-width: 3px;
          transform: translate(5px, 5px);
          stroke-dasharray: 190;
        }
      }
      span {
        position: absolute;
        font-size: 1.5rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  datnek-ds-avatar {
    margin-top: 48px; //margin-top: -37px;
  }

  .footer-modal {
    height: 58px;
    padding: $card-padding;
    border-top: 1px solid $gray-slate-300;
  }

  .list-jury {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    .jury {
      border-radius: $round-card;
      border: 1px solid $gray-slate-300;
      background-color: $white;
      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: $round-card;
      }
    }
  }
}

//  DIALG EDIT MEDIA ================================================
.dialog-edit-media {
  .image {
    position: relative;
    width: 100%;
    height: 150px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &.add {
      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $primary;
        z-index: 0;
        border-radius: 6px;
      }
    }
    datnek-ds-button {
      width: 100%;
      height: 100%;
      display: block;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background-color: $primary;
    }
    input {
      opacity: 0;
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
      border-radius: 6px;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        background-color: $primary;
        z-index: 1;
      }
    }
    .desc {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 45px;
      background-color: $white;
    }
    img {
      &.active {
        border: 4px solid $primary;
      }
    }
  }
}

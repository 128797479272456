/************ MENU DESIGN FILE ************/

// MENU MOBILE ------------------------------------------------------
// Enterprise Menu
.enterprise-mobile-menu {
  .owl-theme .owl-nav [class*="owl-"] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 4px;
    background: transparent !important;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
  }

  .owl-prev {
    left: 0;
    position: absolute;
  }

  .owl-next {
    right: 0;
    position: absolute;
  }
  .owl-theme .owl-nav {
    margin-top: 10px;
    position: absolute;
    top: 160px;
    width: 100%;
  }

  .owl-carousel .owl-item {
    min-height: 1px;
    float: left;
    width: 100% !important;
  }

  .owl-carousel .owl-stage {
    width: 100% !important;
  }
}

// Menu items
.menu-mobile {
  position: relative;
  .mobile-menu-icon {
    display: none;
    background-color: $white;
    box-shadow: 0px 0px 5px rgba($dark, 20%);
    z-index: 11002;
  }
  @media (max-width: 767.98px) {
    .mobile-menu-icon {
      display: flex !important;
      align-items: center;
      justify-content: space-around;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 58px;
      z-index: 9;
      div {
        width: 100%;
        flex: 1;
      }
    }

    .p-sidebar-left {
      width: 23rem !important;
    }
  }

  @media (max-width: 575.98px) {
    .p-sidebar-left {
      width: 100% !important;
    }
  }

  .header-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 0.4rem 1rem;
    background: white;
  }

  .content-sidebar {
    height: calc(100vh - 10px);
    overflow-y: scroll;
    padding-top: 3rem;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .p-sidebar-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.sidebar-chat {
  max-height: 260px;
  overflow-y: scroll;
  -ms-overflow-style: none !important;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.p-sidebar-left {
  z-index: 10;
  height: 100%;
  left: 0;
  top: 0;
  //transform: translateX(-100%);
}

.p-sidebar {
  z-index: 12000 !important;
}

.p-tabview .p-tabview-panels {
  padding: 1rem 0 !important;
}

.p-sidebar-content {
  overflow-y: scroll;
  position: relative;
  -ms-overflow-style: none !important;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.p-sidebar .p-sidebar-header {
  display: none;
}

.chat-list-box {
  display: block;
  position: fixed;
  bottom: 0;
  right: 16px;
  z-index: 12;
  width: 350px;
  height: 530px;
  border-radius: $round-card $round-card 0 0;
  overflow: hidden;
  transform: translateY(530px);
  transition: transform ease-in-out 0.5s;
  @media (max-width: 575.99px) {
    width: 100%;
    right: 0;
    height: 100%;
    border-radius: 0;
    transform: translateY(1830px);
  }
  &.show {
    transform: translateY(55px);
  }
  .header-chat-list {
    background-color: $primary;
    color: $white;
    height: 56px;
  }
  .body-chat-list {
    background-color: $white;
    height: inherit;
    overflow-y: scroll;
    padding-bottom: 4rem !important;
  }
}

.apps {
  -webkit-animation: rotate-scale-up 2s linear 6s infinite both;
  animation: rotate-scale-up 2s linear 6s infinite both;
}

@-webkit-keyframes rotate-scale-up {
  0%,
  50%,
  100% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  25%,
  35% {
    -webkit-transform: scale(1.32) rotateZ(180deg);
    transform: scale(1.32) rotateZ(180deg);
  }
}
@keyframes rotate-scale-up {
  0%,
  50%,
  100% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  25%,
  35% {
    -webkit-transform: scale(1.32) rotateZ(180deg);
    transform: scale(1.32) rotateZ(180deg);
  }
}

// Enterprise list menu mobile
.enterprise-list-mobile-menu {
  .category-body {
    height: calc(100vh - 273px);
    overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .category {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 1rem;
    border-bottom: 1px solid $gray-slate-300;
    transition: all ease-in 0.15s;
    img {
      transition: all ease-in 0.15s;
      opacity: 0;
    }
    &:hover {
      background-color: $home-gray;
      img {
        opacity: 1;
      }
    }
  }
}

// Saved messages menu
.saved-message-menu-mobile {
  .category-body {
    height: calc(100vh - 273px);
    overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .suggestion-footer {
    background-color: $white;
  }

  .suggestion-header {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid $gray-slate-300;
    @media (max-width: 575.98px) {
      padding: 1rem;
    }
  }

  .category {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 1rem;
    border-bottom: 1px solid $gray-slate-300;
    transition: all ease-in 0.15s;
    svg {
      display: none;
    }
    &:hover {
      background-color: $home-gray;
      datnek-ds-svg-icon {
        display: block;
      }
    }
  }
}

// Single groups
.sidebar-users {
  border-top: 1px solid $gray-slate-300;
}

.sidebar-users-group {
  display: grid;
  // gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
}

.suggestion-footer {
  padding: 1rem;
  border-radius: 0 0 $round-card $round-card;
}

.sidebar-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $white;
  z-index: 1000;
}

// MENU SIDEBAR ---------------------------------------------------
.sidebar-menu {
  padding: 0;
  list-style: none;
  li {
    padding: 8px;
    transition: all ease-in 0.15s;
    &:hover {
      border-radius: $round-card;
      background-color: $home-gray;
    }
    a {
      text-decoration: none;
      color: $text !important;
      &.is-active {
        color: $primary !important;
      }
    }
  }
}

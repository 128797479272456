/************* MOLECULES DESIGN FILE *****************/

// AVATAR DROPDOWN -------------------------------------------
.datnek-ds-avatar-dropdown {
  .dropup .dropdown-toggle::after {
    display: none !important;
    content: "";
    border-top: 0 solid !important;
    border-right: 0 solid transparent !important;
    border-bottom: 0 !important;
    border-left: 0 solid transparent !important;
    opacity: 0;
  }

  .dropdown-menu.show {
    transform: translate(0px, 32px) !important;
    width: 200px !important;
    z-index: 200;
  }
}

// CHECKBOX GROUP --------------------------------------------
/* Customize the label (the container) */
.checkbox-group {
  &.container-checkbox {
    display: block;
    position: relative;
    padding: 20px 55px 20px 10px;
    margin-bottom: 0px;
    width: 100%;
    &.small-padding {
      padding: 5px 55px 5px 10px;
    }
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom: 1px solid lightgrey;
    &.markLeft {
      padding: 20px 10px 20px 35px;
      &.small-padding {
        padding: 5px 10px 5px 35px;
      }
      &.no-list {
        padding: 0px 10px 0px 35px;
      }
    }
    &.no-list {
      padding: 0px 55px 0px 10px;
    }
    &:last-child {
      border-bottom: none;
    }
    &:hover input ~ .checkmark {
      background-color: #ccc;
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked ~ .checkmark {
        background-color: $primary;
        &:after {
          display: block;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      height: 25px;
      width: 25px;
      background-color: #fff;
      border-radius: 50%;
      border: 1px solid grey;
      &.markLeft {
        left: 0 !important;
      }
      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 7px;
        left: 8px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }
    }
  }

  .desc {
    color: $gray-300;
  }
}

// COMMENT ADD
.comment-add {
  .datnek-ds-avatar {
    width: 58px;
  }
  datnek-ds-editor-add-comments {
    width: 100% !important;
  }
}

// COMMENT PUSHED
.comment-pushed {
  &.list-comment {
    list-style: none;
    padding-left: 0;
    .comment-box {
      margin-bottom: 1rem;
      .right {
        width: auto;
      }
      .comment-item {
        border-radius: $round-card;
        background-color: $secondary;
        padding: 1rem;
        width: fit-content;
        position: relative;
        .username {
          max-width: 150px;
        }
      }
      .actions {
        position: relative;
        gap: 17px;
        top: -13px;
        left: 5px;
        img {
          width: 15px;
        }
        div {
          cursor: pointer;
        }
      }
      .more-comments {
        position: relative;
        left: -12px;
        top: -5px;
      }
    }
    ul {
      list-style: none;
      padding-left: 45px;
      nk-shared-comment-pushed {
        ul {
          padding-left: 0;
          margin-top: 16px;
        }
      }
    }
  }

  .comment-dropdown {
    position: relative;
    right: -9px;
    top: -4px;
    .dropdown-menu.show {
      transform: translate(-200px, 32px) !important;
      width: 225px !important;
    }
  }

  .timer {
    margin-right: 13px;
  }
}

// EVENT LIST --------------------------------------------------------
.event-list {
  &.user-chat-list {
    border-radius: $round-card;
    transition: all ease-in 0.25s;
    position: relative;
    cursor: pointer;
    &:hover,
    &:active,
    .active {
      background-color: $gray-slate-200;
    }
    .infos {
      .name {
        color: $text;
      }
      span {
        color: $gray-300;
      }
    }
    .user-data {
      width: 100%;
      .img {
        width: 55px !important;
        height: 55px !important;
        overflow: hidden;
        img {
          width: inherit;
          height: inherit;
          object-fit: cover;
        }
      }
    }
    .time {
      color: $gray-300;
    }
    datnek-ds-notification-point {
      position: absolute;
      right: 6px;
      bottom: 6px;
    }
  }

  datnek-ds-search-input  {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .img {
    width: 80px;
    height: 41px;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 1rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

// FILTER CHIP --------------------------------------------------------
.filter {
  display: flex;
  gap: 16px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  @media (max-width: 991.98px) {
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
  .chip {
    min-width: -moz-fit-content;
    min-width: fit-content;
    background-color: $home-gray;
    padding: 0rem 1.4rem;
    border-radius: 26px;
    display: flex;
    align-items: center;
    height: 30px;
    cursor: pointer;
    &:hover {
      background-color: darken($home-gray, 1.2);
    }
    &.active {
      background-color: $primary;
      color: $white;
    }
  }
}

// GROUP CARD --------------------------------------------------------
.group-card {
  &.suggestion-list-card {
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }

  .network-user-suggestion-small {
    overflow: hidden;
    transition: all ease 0.1.5;
    transform: scale(1);
    .img {
      width: 100%;
      height: 150px;
      margin-bottom: 0;
      position: relative;
      overflow: hidden;
      background: #4b4b4b;
      img {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }
    .infos {
      gap: 10px;
      position: relative;
      datnek-ds-avatar {
        position: absolute;
        right: 16px;
        top: -14px;
      }
      .display-8 {
        color: $gray-300;
      }
    }
    &.inline-box {
      .infos {
        gap: 10px;
        width: 70%;
      }
    }
    @media (max-width: 567px) {
      display: flex !important;
      flex-direction: column !important;
      .video {
        width: 100% !important;
        height: 150px !important;
      }
    }
  }

  .name {
    width: 100%;
  }
}

// ARTICLE CARD --------------------------------------------------------
.article-card {
  &.suggestion-list-card {
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }

  .network-user-suggestion-small {
    overflow: hidden;
    transition: all ease 0.1.5;
    transform: scale(1);
    .img {
      width: 100%;
      height: 150px;
      margin-bottom: 0;
      position: relative;
      overflow: hidden;
      background: #4b4b4b;
      img {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }
    .infos {
      gap: 10px;
      position: relative;
      datnek-ds-avatar {
        position: absolute;
        right: 16px;
        top: -14px;
      }
      .display-8 {
        color: $gray-300;
      }
    }
    &.inline-box {
      .infos {
        gap: 10px;
        width: 70%;
      }
    }
    @media (max-width: 567px) {
      display: flex !important;
      flex-direction: column !important;
      .video {
        width: 100% !important;
        height: 150px !important;
      }
    }
  }

  .name {
    width: 100%;
  }
}

// ICON SUBMENU ----------------------------------------------------
.icon-submenu {
  .display-5 {
    color: $text;
    white-space: break-spaces;
  }

  .display-7 {
    color: $gray-300;
    white-space: break-spaces;
  }

  .icon-submenu {
    width: auto; //300px
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: text;
    text-decoration: none;
    background-color: $gray-300;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: text;
    text-decoration: none;
    background-color: $gray-300;
  }
}

// ICON TEXT DROPDOWN ----------------------------------------------------
.icon-text-dropdown,
.simple-text-dropdown {
  button {
    color: $gray-300;
    &.icon-profile {
      border-radius: $round;
      border: 1px solid $gray-300;
      padding: 0.3rem 0.4rem;
    }
  }
  .icon-profile-dropdown {
    button {
      color: $gray-400;
    }
  }
}

// IMAGE TEXT DROPDOWN ----------------------------------------------------
.datnek-ds-image-text-dropdown {
  button {
    border-radius: $round;
    border: 1px solid $gray-300;
    color: $gray-300;
    &.icon-profile {
      .label {
        @media (max-width: 575.98px) {
          max-width: 90px;
        }
      }
    }
  }
  &--block {
    width: 100%;
    button {
      width: 100%;
      height: 41px;
      position: relative;
      datnek-ds-svg-icon {
        position: relative !important;
        right: 0 !important;
        top: 0 !important;
      }
    }
    .dropdown-menu {
      width: 100%;
    }
  }
  .icon-profile-dropdown {
    button {
      border-radius: none;
      border: none;
    }
  }
}

// TEXT DROPDOWN ----------------------------------------------------
.datnek-ds-text-dropdown {
  button {
    border-radius: $round;
    border: 1px solid $gray-300;
    color: $gray-300;
    .icon-profile {
    }
  }
  &--block {
    width: 100%;
    button {
      width: 100%;
      height: 41px;
      position: relative;
      datnek-ds-svg-icon {
        position: relative !important;
        right: 0 !important;
        top: 0 !important;
      }
    }
    .dropdown-menu {
      width: 100%;
    }
  }
  .icon-profile-dropdown {
    button {
      border-radius: none;
      border: none;
    }
  }
}

// SUGGESTION LIST ------------------------------------------------------
.suggestion-list {
  &.nk-shared-user-infos-small {
    transition: all ease-in 0.15s;
    &:hover {
      text-decoration: none;
      background-color: $home-gray;
      // border-radius: $round-card;
    }
    .infos {
      width: 100%;
      &:hover {
        text-decoration: none;
      }
      .name {
        color: $text;
        position: relative;
        img {
          position: absolute;
          right: -30px;
        }
      }
      span {
        color: $gray-300;
        font-size: $text-normal !important;
      }
    }
    .datnek-ds-avatar--medium {
      height: 45px;
      width: $img-tiny;
    }
  }

  .roles {
    margin-top: -20px;
    position: relative;
    right: -5px;
  }

  .dropdown-menu.show {
    width: 220px !important;
    transform: translate(-175px, 60px) !important;
  }
}

// SUGGESTION FULL LIST ------------------------------------------------------
.suggestion-full-list {
  &.suggestion-container {
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    @media (min-width: 768.98px) {
      //grid-template-columns: repeat(3, 1fr);
    }
  }
}

// INVITATION LIST -----------------------------------------------------------
.invitation-list {
  &.nk-shared-user-infos-small {
    transition: all ease-in 0.15s;
    &:hover {
      text-decoration: none;
      background-color: $home-gray;
      border-radius: 0px;
    }
  }

  .datnek-ds-button--medium {
    padding: 0px 10px !important;
  }

  .buttons {
    width: 100%;
  }

  .name {
    position: relative;
    img {
      position: absolute;
      right: -22px;
      top: 0;
    }
  }
}

// JOB LIST -----------------------------------------------------
.job-list-item {
  color: $text;
  transition: all ease-in 0.15s;
  &:hover {
    text-decoration: none;
    background-color: $home-gray;
    border-radius: $round-card;
  }
  img {
    width: 50px;
    border-radius: 4px;
  }
  .display-4,
  .display-5,
  .display-8 {
    color: $gray-300;
  }
}

// LIKES ICONS POPBOX ---------------------------------------------
.likes-icons {
  //display: none;
  //position: absolute;
  gap: 10px;
  padding: 16px;
  cursor: pointer;
  border-radius: $round-card;
  z-index: 1000;
  &.bottom {
    //bottom: -115px;
    //left: -80px;
  }
  &.top {
    top: -75px;
    left: -20px;
  }
  @media (max-width: 575.98px) {
    left: -81px;
  }
  &.grid-icons {
    display: flex !important;
    width: 212px;
    overflow: hidden;
    datnek-ds-button-icon {
      position: relative !important;
      right: 0 !important;
      top: 0 !important;
    }
    &.full-grids {
      display: grid !important;
      width: auto !important;
      grid-template-columns: repeat(4, 1fr);
      //transform: translateY(90px);
      @media (max-width: 575.98px) {
        //transform: translateY(80px);
      }
      &.top {
        //transform: translateY(0px);
      }
    }
  }
  img {
    transition: all ease-in 0.18s;
    &:hover {
      transform: scale(1.5);
      transform-origin: center;
    }
  }
}


// USER INFOS ---------------------------------------
.nk-shared-user-infos-small {
  width: 100%;
  .infos {
    width: 100%;
    .top-infos {
      width: 100%;
      @media (max-width: 575.99px) {
        width: 90%;
      }
      .name {
        position: relative;
        img {
          position: relative;
          width: 18px;
          height: 18px;
        }
        span {
          color: $text;
        }
      }
    }
    span {
      color: $gray-300;
      font-size: $text-normal !important;
    }
    img {
      transform: scale(0.7);
    }
  }
  .user-dadas {
    .time {
      color: $gray-300;
    }
    .point {
      content: "";
      width: 4px;
      height: 4px;
      display: block;
      position: relative;
      background-color: $text;
      border-radius: $round;
    }
  }
  .submenu-competences {
    display: none;
    position: absolute;
    top: 23px;
    left: 0;
    width: calc(563px - 110px);
    padding: 16px;
    z-index: 1;
    color: $gray-300;
    @media (max-width: 575.99px) {
      width: 275px;
    }
  }

  .dropdown-menu.show {
    transform: translate(0, 35px) !important;
  }
}

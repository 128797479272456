.sso-login {
  background-color: #fff;
  max-height: 100vh;
  min-height: unset;

  p {
    margin-bottom: 0 !important;
  }

  .brand-wrapper {
    padding-top: 7px;
    padding-bottom: 8px;

    .logo {
      height: 25px;
    }
  }

  .login-title {
    font-size: 1.3rem;
  }

  .login {
    &-section-wrapper {
      display: flex;
      flex-direction: column;
      padding: 64px 40px;
      background-color: #fff;

      @media (max-width: 991px) {
        padding-left: 50px;
        padding-right: 50px;
      }
      @media (max-width: 575px) {
        padding: 50px 15px !important;
        min-height: 100vh;
      }
    }

    &-wrapper {
      width: 75%;
      max-width: 100%;
      // padding-top: 24px;
      // padding-bottom: 24px;
      z-index: 1;

      @media (max-width: 575px) {
        width: 100%;
      }

      .custom-radio {
        label {
          font-size: 16px;
        }
      }

      label {
        /* font-size: 12px; */
        font-weight: 500;
      }

      .form-control {
        border: none;
        border: 1px solid #929292;
        border-radius: 4px;
        padding: 0.375rem 0.75rem;
        min-height: 40px;
        font-size: 18px;
        font-weight: normal;

        &::placeholder {
          color: #b0adad;
          font-size: 14px !important;
        }
        &:focus {
          box-shadow: none !important;
        }
      }

      .login-btn {
        padding: 10px 20px;
        background-color: blue;
        border-radius: 25px;
        font-size: 15px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 14px;
        text-decoration: none;

        &-cancel {
          background-color: red;
        }

        &:hover {
          border: 1px solid blue;
          background-color: #fff;
          color: blue;
        }
      }

      a.forgot-password-link {
        color: #080808;
        font-size: 14px;
        text-decoration: underline;
        display: inline-block;
        margin-bottom: 54px;

        @media (max-width: 575px) {
          margin-bottom: 16px;
        }
      }

      &-footer-text {
        font-size: 16px;
        color: #000;
        margin-bottom: 0;
      }
    }

    &-title {
      font-size: 30px;
      color: #000;
      font-weight: 500;
      @media (max-width: 575px) {
        font-size: 1.4rem;
      }
    }

    &-img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      object-position: center;
    }
  }

  .login-left {
    position: relative;
    max-height: 100vh;
    padding-right: 7px !important;
    .login-img {
      object-fit: cover;
    }

    .person-item {
      position: relative;

      &::before {
        content: '';
        background: rgba($color: #e4e6eb, $alpha: 0.52);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
      &::after {
        content: '';
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.29) 100%
        );
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
      }
    }

    .b-hover {
      background: rgba($color: black, $alpha: 0.6);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    .infos-perso {
      position: absolute;
      bottom: 0;
      left: 0;
      color: white;
      z-index: 3;
      padding: 0rem 1rem 3rem 1rem !important;

      @media (min-width: 768px) and (max-width: 991.98px) {
        padding: 0rem 2rem 3rem 2rem !important;
      }
      @media (min-width: 992px) {
        padding: 0 3rem 5rem !important;
      }

      .slide-infos {
        color: $white;
        .slide-title {
          //text-transform: uppercase;
          font-size: 26px;
          font-weight: 600;
        }

        .slide-description {
          font-size: 17px;
        }
      }
    }
  }

  .logo-top {
    position: absolute;
    top: 10px;
    left: 35px;
    z-index: 2;
    @media (max-width: 575px) {
      left: 50%;
      transform: translateX(-50%);
    }

    img {
      &.logo {
        width: 150px;
      }
    }
  }

  .login-right {
    position: relative;
    //overflow: auto;
    height: 100vh;
    @media (max-width: 576.98) {
      padding: 0 2rem 0 2rem !important;
    }
    padding: 0 2rem 0 2rem !important;

    .icon-bg {
      position: absolute;
      left: -121%;
      opacity: 0.022;
      width: 207%;
      z-index: 0;
    }

    .brand-wrapper {
      position: relative;
    }

    .foot {
      position: fixed;
      width: inherit;
      bottom: 0;
      right: 0;
      text-align: right;
      padding: 1rem 2rem;
      background: white;
      @media (max-width: 575px) {
        padding: 1rem 1rem;
      }
    }

    a {
      //color: #000000;
      //text-decoration: underline;
      font-size: 0.9rem;
    }
  }

  .code {
    input {
      width: 75px;
      height: 100px;
      text-align: center;
      font-size: 2rem;
      border: 1px solid #e5e5e5;
      margin-right: 10px;
      border-radius: 5px;

      &::placeholder {
        color: #e5e5e5;
        font-size: 14px !important;
      }
    }

    .code-middle {
      margin-right: 10px;
    }
  }

  .alert {
    font-size: 0.8rem;
  }

  .login-slider {
    position: relative;
    max-height: 100vh;

    .slick-dots {
      position: absolute;
      bottom: 25px;
      list-style: none;
      display: block;
      text-align: right;
      padding: 19px;
      margin: 0;
      width: 100%;

      li {
        button {
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: '•';
            width: 20px;
            height: 20px;
            font-family: 'slick';
            font-size: 50px;
            line-height: 20px;
            text-align: center;
            color: white;
            opacity: 0.25;
          }
        }

        &.slick-active {
          button {
            &::before {
              color: white;
              opacity: 0.75;
            }
          }
        }
      }
    }

    .slide {
      .b-hover {
        background: rgba($color: black, $alpha: 0.6);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }

      .infos-slide {
        position: absolute;
        bottom: 0;
        left: 0;
        color: white;
        z-index: 2;
        padding: 30px;

        .quotes {
          font-size: 1.2rem;
          font-weight: 300;
        }

        h1 {
          font-size: 2rem;
          font-weight: 600;
        }
      }
    }
  }

  @media (max-width: 575px) {
    .login-wrapper-footer-text {
      font-size: 14px;
    }
  }

  // P-CAROUSEL CUSTOM
  .p-carousel-prev {
    display: none !important;
  }

  .p-carousel-next {
    display: none !important;
  }

  .p-carousel-indicators {
    justify-content: flex-end;
    padding: 0;
  }

  .p-carousel-next {
    display: none !important;
  }

  .p-carousel-indicators {
    justify-content: flex-end;
    padding: 0;
    padding: 0;
    position: absolute;
    bottom: 30px;
    right: 30px;
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator .p-highlight button {
    background-color: blue !important;
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #e9ecef;
    width: 1rem;
    height: 1rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 16px;
  }

  //OWL CARUSEL
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: -42px;
    position: relative;
  }
  .owl-theme {
    .owl-dots {
      .owl-dot {
        zoom: 1.5;
        span {
          margin: 5px 4px;
          background: rgba($white, 0.6) !important;
        }
        &.active span,
        &:hover span {
          background: $white !important;
        }
      }
    }
  }
}

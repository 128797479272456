.float-group-btn-add {
  position: fixed;
  bottom: 90px;
  right: 25px;
  z-index: 10;
}

.datnek-ds-content {
  min-height: 100vh;
}

.suggestion-header {
  background-color: $white;
  border-radius: $round-card $round-card 0 0;
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  min-height: 58px;
  border-bottom: 1px solid #e9e7e7;
  .nk-shared-user-infos-small {
    datnek-ds-avatar {
      width: 58px;

      @media (max-with: 400px) {
        width: 43px;
        img {
          width: 43px;
          height: 43px;
        }
      }
    }
    .infos {
      width: 100%;
    }
  }
}

.category-body {
  height: 226px;
  overflow-y: scroll;
}

.suggestion-footer {
  padding: 1rem;
  background-color: $white;
  border-radius: 0 0 $round-card $round-card;
}

// .suggestion-header {
//   padding: 1rem 1.5rem;
//   border-bottom: 1px solid $gray-slate-300;
//   @media (max-width: 575.98px) {
//     padding: 1rem;
//   }
// }

.groups-list {
  height: 160px;
  //overflow-y: scroll;
}

// HOME GROUP =========================================
.home-group {
  .suggestion-list-card {
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }

  .content-filter {
    .side {
      width: 50px;
    }
    .custom-slider-main {
      display: flex;
      overflow: hidden;
      scroll-behavior: smooth;
      width: 300px;
    }
  }

  .suggestion-header {
    background-color: $white;
    border-radius: $round-card $round-card 0 0;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #e9e7e7;
    .nk-shared-user-infos-small {
      datnek-ds-avatar {
        width: 58px;

        @media (max-with: 400px) {
          width: 43px;
          img {
            width: 43px;
            height: 43px;
          }
        }
      }
      .infos {
        width: 100%;
      }
    }
  }

  .suggestion-footer {
    padding: 0.7rem;
    border-top: 1px solid #e9e7e7;
    border-radius: 0 0 $round-card $round-card;
  }

  .category-body {
    height: 226px;
    overflow-y: scroll;
  }

  .suggestion-footer {
    background-color: $white;
  }

  .filter {
    display: flex;
    gap: 16px;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    @media (max-width: 991.98px) {
      -ms-overflow-style: none !important;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .chip {
      min-width: -moz-fit-content;
      min-width: fit-content;
      background-color: $home-gray;
      padding: 0rem 1.4rem;
      border-radius: 26px;
      display: flex;
      align-items: center;
      height: 30px;
      cursor: pointer;
      &.active {
        background-color: $primary;
        color: $white;
      }
    }
  }

  .owl-theme .owl-nav [class*="owl-"] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 4px;
    background: transparent !important;
    display: none;
    cursor: pointer;
    border-radius: 3px;
    transition: all ease-in 0.15s;
    img {
      width: 30px;
    }
  }

  @media (max-width: 575.98px) {
    .owl-theme .owl-nav [class*="owl-"] {
      display: inline-block !important;
    }
  }

  .owl-theme:hover .owl-nav [class*="owl-"] {
    display: inline-block;
  }

  .owl-prev {
    left: -35px;
    position: absolute;
  }

  .owl-next {
    right: -35px;
    position: absolute;
  }
  .owl-theme .owl-nav {
    position: absolute;
    top: 50%;
    /* transform: translateY(50%); */
    margin-top: -32px;
    width: 100%;
  }

  // .dropdown-formly-menu.show {
  //   display: block;
  //   position: fixed !important;
  //   bottom: 89px !important;
  //   top: unset !important;
  //   width: 100%;
  //   z-index: 12000000;
  //   padding: 16px;
  // }
}

// RESTRICTED GROUP ======================================
.restricted-groups {
  background-color: $home-gray !important;
  .sidebar-users {
    border-top: 1px solid $gray-slate-300;
    .sidebar-users-group {
      display: flex;
      // gap: 10px;
      //grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
      align-items: center;
      justify-content: center;
    }
  }
}

// SINGLE GROUPS =========================================
.single-groups {
  background-color: $home-gray !important;
  .datnek-ds-content {
    // top: 54px;
    // position: relative;
    @media (max-width: 575.98px) {
      top: 55px;
      position: relative;
      margin-top: 0;
      min-height: unset !important;
    }
  }

  .sticky-box {
    position: sticky;
    top: 78px;
    z-index: 1;
  }

  .header-group-menu {
    background-color: $white;
    padding: 1rem;
    position: sticky;
    top: 78px;
    z-index: 110;
    @media (max-width: 575.99px) {
      padding: 0.4rem;
      position: fixed;
      top: 54px;
      width: 100%;
      left: 0;
      border-top: 1px solid $gray-slate-300;
      border-bottom: 1px solid $gray-slate-300;
    }
    .menu,
    .actions {
      width: 100%;
    }
    .menu {
      datnek-ds-button {
        flex: 1;
      }
      @media (max-width: 767.99px),
        (min-width: 992px) and (max-width: 1199.98px) {
        padding: 0.4rem;
        position: absolute;
        top: 65px;
        left: 0;
        background: white;
        width: 100%;
        border-top: 1px solid $gray-slate-300;
        border-bottom: 1px solid $gray-slate-300;
      }
      @media (min-width: 768px) and (max-width: 991.98px) {
        padding: 0;
        position: relative;
        top: 0;
        width: 100%;
        border-top: 0;
        border-bottom: 0;
      }
      @media (max-width: 575.99px) {
        top: 55px;
      }
    }
    .dropdown-menu.show {
      transform: translate(-182px, 35px) !important;
    }
  }

  .searchbox {
    background-color: $white;
    position: absolute;
    right: 16px;
    top: 0;
    width: 96%;
    height: -webkit-fill-available;
    z-index: 1;
    @media (max-width: 767.98px) {
      top: 0;
      padding: 5px 7px;
      width: 100%;
      right: 0;
      height: 100%;
    }
    datnek-ds-search-input  {
      width: 100%;
    }
  }

  .input-search-topbar {
    position: absolute !important;
    z-index: 12;
    background: white;
    height: 50px;
    width: 97%;
    input[type="text"] {
      height: 58px;
      margin-top: -5px;
      font-size: 15px;
      display: inline-block;
      border: none;
      outline: none;
      color: #555;
      padding: 0rem 4rem;
      width: 100%;
      background: none;
      z-index: 3;
      transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
      cursor: text;
      @media (max-width: 560px) {
        padding: 0rem 1rem;
        font-size: 16px;
        margin-top: -4px;
      }
    }
    datnek-ds-button-icon {
      position: absolute;
      right: 5px;
      top: 6px;
    }
  }

  datnek-ds-button.is-active {
    background: $gray-slate-300 !important;
    border-radius: 6px;
  }

  // :host ::ng-deep .dropdown-formly-menu.show {
  //   display: block;
  //   transform: translate(-160px, 32px) !important;
  //   width: 200px !important;
  // }

  @media (min-width: 768.99px) {
    nk-shared-card-user-box {
      position: sticky;
      top: 78px;
    }
  }

  .multimedia {
    .p-tabview-panel {
      padding: 0 1rem;
    }
  }

  .filter-media {
    .dropdown-menu.show {
      transform: translate(-82px, 19px) !important;
    }
  }
}

// Group discussion

.hashtag-list {
  width: 100%;
  border-bottom: 1px solid $gray-slate-300;
  .symbol {
    color: $gray-300;
  }
}

// Group multimedia
.date {
  color: $gray-300;
}

.link-item {
  padding: 1rem;
  border-radius: 13px;
  border: 1px solid $gray-slate-300;
  margin-bottom: 1rem;
}

.p-tabview .p-tabview-panels {
  border-bottom-right-radius: $round-card;
  border-bottom-left-radius: $round-card;
}

.p-tabview .p-tabview-nav {
  border: none;
  background-color: transparent !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link:hover {
  background: transparent;
  border-width: 0 0 2px 0 !important;
  border-color: #0e76b2;
  color: #0e76b2;
  width: max-content;
  .head-react {
    height: 28px;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    img {
      width: 20px;
    }
  }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  //border: solid #ffffff;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #ffffff transparent;
  background: transparent;
  color: $text-semi-dark;
  padding: 0.8rem 1rem;
  width: max-content;
  .head-react {
    height: 25px;
    img {
      transform: scale(0.7);
    }
  }
  @media (max-width: 575.98px) {
    padding: 1rem 0.6rem;
  }
  @media (max-width: 991.98px) {
    padding: 0.5rem 0.7rem;
  }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

// Filter media group
// :host ::ng-deep .dropdown-formly-menu.show {
//   transform: translate(-122px, 32px) !important;
// }

// GROUP SETTINGS ==============================================
.group-settings {
  .suggestion-content {
  }
}

// Group settings -- Customize
.edit {
  margin: auto;
  width: 50%;
  border-top: 1px solid $gray-slate-300;
  border-bottom: 1px solid $gray-slate-300;
  @media (max-width: 575.98px) {
    width: 100% !important;
  }
  @media (max-width: 767.98px) {
    width: 70%;
  }
  .titles {
    display: flex;
    flex-direction: column;
    span {
      color: $gray-300;
    }
  }
  .form {
    border: 1px solid $gray-300;
    border-radius: $round-card;
    margin-bottom: 1rem;
  }
}

.yes-no {
  width: 100%;
  datnek-ds-checkbox-group {
    flex: 1;
  }
}

// Groupe settings -- Home
.settings-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $gap;
  @media (max-width: 575.98px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .item {
    display: flex;
    align-items: center;
    border: 1px solid $gray-slate-300;
    padding: 1rem;
    border-radius: 13px;
    transition: all ease-in 0.2s;
    &:hover {
      background-color: $secondary-dark;
    }
    cursor: pointer;
    .icon {
      width: 15%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .infos {
      width: 85%;
      display: flex;
      flex-direction: column;
      span {
        color: $gray-300;
      }
    }
  }
}

// Group settings -- Quizz
.quizz {
  @media (max-width: 575.98px) {
    height: calc(100vh - 190px);
  }

  .add-quizz {
    min-height: 200px;
    @media (max-width: 575.98px) {
      min-height: auto;
    }
    .quizz {
      border-radius: $round-card;
      border: 1px solid $gray-slate-300;
      width: 70%;
      margin-bottom: 1rem;
      @media (max-width: 575.98px) {
        width: 100%;
      }
      @media (max-width: 767.98px) {
        width: 85%;
      }
    }
  }
  .no-content {
    height: auto;
  }
}

// Group stats -- Validation User
.groups-validation-user {
  .responses-details {
    .user {
      width: 30%;
      border: 1px solid $gray-slate-300;
      @media (max-width: 767.98px) {
        width: 100%;
      }
      .actions {
        gap: $gap;
        datnek-ds-button {
          flex: 1;
        }
      }
    }
    .responses {
      width: 70%;
      @media (max-width: 767.98px) {
        width: 100%;
      }
    }
  }

  .add-quizz {
    max-height: 400px;
    //overflow-y: scroll;
    @media (max-width: 575.98px) {
      max-height: 235px;
    }
    .quizz {
      width: 100%;
    }
  }

  .display-4 {
    color: $gray-300;
  }
}

// Group stats -- Post Signalization
.groups-post-signalization {
  .sticky-right {
    position: sticky;
    top: 165px;
    z-index: 1;
  }

  .dropdown-menu.show {
    transform: translate(-130px, 35px) !important;
  }

  .p-tabview .p-tabview-panels {
    padding: 0;
  }

  .suggestion-header {
    position: relative;
  }

  .suggestion-footer {
    margin-top: -64px;
    z-index: 100;
    position: relative;
    border-top: 1px solid $gray-slate-300;
  }

  .publication-box {
    margin-bottom: 0 !important;
  }

  @media (max-width: 575.98px) {
    .post {
      width: 100vw;
      position: fixed;
      top: 63px;
      min-height: calc(100vh - 122px);
    }
  }

  .suggestion-content {
    max-height: calc(100vh - 196px);
    overflow-y: hidden;
    @media (max-width: 575.98px) {
      max-height: calc(100vh - 196px);
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .posts {
      transition: all ease 1.5s;
      &:hover {
        background-color: $gray-slate-200;
      }
      input[type="checkbox"],
      input[type="radio"] {
      }
    }
    &.active {
    }
  }

  .p-scrollpanel.p-component {
    height: calc(100vh - 193px) !important;
  }

  .list-signalizations {
    padding: 1rem;
    list-style: none;
    li {
      margin-bottom: 1rem;
    }
  }

  .allposts {
    position: relative;
    height: 42px;
    .post-list {
      position: fixed;
      z-index: 2;
      top: 125px;
      @media (max-width: 575.99px) {
        top: 68px;
        left: 16px;
      }
      .datnek-ds-card {
        position: absolute;
        top: 55px;
        left: -16px;
        z-index: 2;
        width: 100vw;
        height: calc(100vh - 180px);
      }
    }
  }
}

// Group stats -- Post Pending
.groups-post-pending {
  .posts {
    border-bottom: 1px solid $gray-slate-300;
    transition: all ease-in 0.2s;
    &:hover {
      background-color: $secondary;
    }
  }
  .post {
    margin-top: 5px;
  }
}

// Group Stats -- Post moderation
.groups-post-moderation {
  .suggestion-content {
    max-height: calc(100vh - 280px);
    overflow-y: hidden;
    @media (max-width: 575.98px) {
      max-height: calc(100vh - 280px);
    }
  }
}

// Group stats -- home
.home-groups-stats {
  .stats-content {
    border-bottom: 1px solid $gray-slate-300;
    border-top: 1px solid $gray-slate-300;
    .title {
      span {
        color: $gray-300;
      }
    }
    .stats-items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $gap;
      @media (max-width: 767.98px) {
        grid-template-columns: repeat(1, 1fr);
      }
      &.publications {
        .item {
          .icon {
            width: 15%;
          }
          .infos {
            width: 55%;
          }
          .number {
            width: 10%;
          }
          .growth {
            width: 15%;
            span {
              font-size: 1.2rem;
              &.more {
                color: $success;
              }
              &.less {
                color: $danger;
              }
              &.none {
                color: $gray-300;
              }
            }
          }
        }
      }
      .item {
        display: flex;
        align-items: center;
        border: 1px solid $gray-slate-300;
        padding: 1rem;
        border-radius: 13px;
        transition: all ease-in 0.2s;
        &:hover {
          background-color: $secondary-dark;
        }
        cursor: pointer;
        .icon {
          width: 15%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .infos {
          width: 75%;
          display: flex;
          flex-direction: column;
          span {
            color: $gray-300;
          }
        }
        .number {
          width: 10%;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 1.2rem;
            font-weight: 700;
          }
        }
      }
    }
  }

  datnek-ds-svg-icon.mr-3 {
    transform: scale(0.9) translate(0px, -4px);
  }
}

// Group stats -- Adhesion list
.list-adhesion {
  border-bottom: 1px solid $gray-slate-300;
  &:hover {
    background-color: $home-gray;
    // border-radius: $round-card;
  }
  .user-infos {
    transition: all ease-in 0.15s;

    .infos {
      width: 100%;
      &:hover {
        text-decoration: none;
      }
      .name {
        color: $text;
        position: relative;
        img {
          position: absolute;
          right: -30px;
        }
      }
      span {
        color: $gray-300;
      }
    }
    .datnek-ds-avatar--medium {
      height: 45px;
      width: $img-tiny;
    }
  }
}

// Sidebar group right
.sidebar-group-right {
  .sidebar-users {
    border-top: 1px solid $gray-slate-300;
  }

  .sidebar-users-group {
    display: grid;
    // gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
  }
}
